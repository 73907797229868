<div class="modal-header bg-light py-2 border-bottom">
    <h5 class="modal-title font-16-bold  pull-left">Configuration Changed</h5>
</div>
<div class="modal-body-scrollable">
    <div class="container">&nbsp;
        <!-- <h6>{{message}}</h6>
        <div></div><br>

        <button type="submit" class="btn btn-1" ngbAutofocus (click)="confirm()">Yes</button>&nbsp;&nbsp;
        <button class="btn btn-1-outline" (click)="close()">No</button> -->
        <p>{{message}}</p>
        <div *ngIf="showSchedule">
            <div class="checkbox">
                <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="isChecked"
                        (change)="checkValue($event.target.checked)">&nbsp;&nbsp;Remove all future recurrance
                    also</label>
            </div>
        </div>
        <div *ngIf="rejectProject">
            <div class="custom-form-group">
                <label class="control-label">If yes, enter reason.</label>
                <textarea class="form-control" [(ngModel)]="remark"></textarea>
            </div>
        </div>
        <div></div><br>

        <button type="submit" class="btn btn-1" ngbAutofocus (click)="confirm()">Click here to
            re-login</button>&nbsp;&nbsp;
        <!-- <button class="btn btn-1-outline" (click)="close()">No</button> -->
    </div>
</div>
<!--  <div class="modal-footer">
       <button type="submit" class="btn btn-1" ngbAutofocus  (click)="confirm()">Yes</button>
      <button class="btn btn-1-outline"   (click)="close()">No</button>
  </div>
   -->