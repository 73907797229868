import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WebService } from '../http/web-service.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        public _webService: WebService,
        private _router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let roles = route.data["roles"] as Array<string>;
        console.log("Roles======", roles, this._webService.loggedIn(), roles == null, this._webService.role);
        if (this._webService.loggedIn() && roles == null || (roles && roles.indexOf(this._webService.role) != -1)) {
            return true;
        }
        else {
            this._router.navigate(['/']);
            return false;
        }
    }

}