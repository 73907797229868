import { LoginCheckComponent } from './modules/auth/pages/login-check/login-check.component';
import { EventService } from 'src/app/core/services/events.service';
import { ContextMenuModule, ContextMenuService } from 'ngx-contextmenu';
import { MessagingService } from 'src/app/core/services/messaging.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// Ngx Boostrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrManager, ToastrModule } from 'ng6-toastr-notifications';
import { AuthGuard } from './core/guards/auth.guard';
import { WebInterceptor } from './core/interceptors/web-interceptor';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Import library module
import { NgxFilesizeModule } from 'ngx-filesize';
import { FileUploadModule } from 'ng2-file-upload';
import { LoginComponent } from './modules/Auth/pages/login/login.component';
import { ForgotPasswordComponent } from './modules/Auth/pages/forgot-password/forgot-password.component';
import { ReactiveFormsModule } from '@angular/forms';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { PwaService } from './core/services/PwaService';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RoleGuard } from './core/guards/role.guard';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ComponentLoaderFactory } from 'ngx-bootstrap/component-loader';
import { PositioningService } from 'ngx-bootstrap/positioning';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { APP_INITIALIZER } from '@angular/core';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { ExportPMGReportComponent } from './modules/auth/pages/export-pmg-report/export-pmg-report.component';
import { ExportMosReportComponent } from './modules/auth/pages/export-mos-report/export-mos-report.component';



// function initializeApp(): Promise<any> {
//   return new Promise(async (resolve, reject) => {
//     try {
//       // Check if Service Worker is supported by the Browser
//       if (this.swUpdate.isEnabled) {
//         const isNewVersion = await this.swUpdate.checkForUpdate();
//         // Check if the new version is available
//         if (isNewVersion) {
//           const isNewVersionActivated = await this.swUpdate.activateUpdate();
//           // Check if the new version is activated and reload the app if it is
//           if (isNewVersionActivated) window.location.reload();
//           resolve(true);
//         }
//         resolve(true);
//       }
//       resolve(true);
//     } catch (error) {
//       window.location.reload();
//     }
//   });
// }

// import { NgxLocalStorageModule, NGX_LOCAL_STORAGE_SERIALIZER } from 'ngx-localstorage';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent, ForgotPasswordComponent,
    LoginCheckComponent,
    ExportPMGReportComponent,
    ExportMosReportComponent
  ],
  imports: [
    BrowserModule, NgxSpinnerModule,
    AppRoutingModule, HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxImageZoomModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production,registrationStrategy: 'registerImmediately', }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    ContextMenuModule.forRoot()
  ],
  exports: [],
  providers: [DatePipe, BsModalService, ComponentLoaderFactory, PositioningService,EventService, {

    provide: HTTP_INTERCEPTORS,
    useClass: WebInterceptor,
    multi: true,
  },
 
    AuthGuard, PwaService, RoleGuard,
    // { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [SwUpdate], multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
