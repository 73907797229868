import { EventService } from 'src/app/core/services/events.service';
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToastrManager } from "ng6-toastr-notifications";
import { Subscription } from "rxjs/internal/Subscription";
import { DatePipe } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class WebService {
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;

  token: string;
  name: string;
  role: string;
  code: string;
  project_approvar: string;
  id: string;
  appAccess: any[];
  profilePic: any;
  companyLogo: any;
  perPage = 20;
  templateId: any;
  templatearray: any;
  item: any;
  isDesc: boolean = false;
  dashboard_type: string = null;
  optionType: string = null;
  new_type: string = null;
  viewFlag: boolean = false;
  appAccessSO: any;
  accessLable:any="";

  modelObj:any;
  
  pmoRoleId=8;
  
MimeTypes1='application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel';
  
config={
  toolbar: ['heading', '|', 'bold',
  'italic', 'fontFamily', 'underline', 'strikethrough', 'alignment',
  'highlight', 'numberedList', 'insertTable',
  'bulletedList', '|', 'indent',
  'outdent', 'undo', 'redo', 'fontBackgroundColor',
  'fontColor'],

heading : {
  options: [
    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
  ]
}
}

  constructor(private http: HttpClient, private datePipe: DatePipe,
    public toastr: ToastrManager, private _router: Router,private _eventService:EventService,
    private storageService: StorageService
  ) {
    let user = this.getUserFromSorage();
    this.pmoRoleId=8;

    if (user) {
      this.token = user.token;
      this.name = user.name;
      this.role = user.role;
      this.id = user.id;
      this.code = user.code;
      this.project_approvar = user.project_approvar;
      if (user.appAccess) {
        try {

          this.appAccess = JSON.parse(user.appAccess);
        } catch (e) {

        }
      }
      if (user.appAccessSO) {
        try {

          this.appAccessSO = JSON.parse(user.appAccessSO);
        } catch (e) {

        }
      }
    }
    this.modelObj={
      class: 'modal-lg animate__slideInRight animate__animated animated_faster',
      animated: true,
      backdrop: true,
      keyboard:false
    }
    if (this.getCompanyLogosFromSorage()) {
      this.companyLogo = this.getCompanyLogosFromSorage();
    } else {
      this.companyLogo = "assets/images/logo.png";
    }
    if (this.getLogosFromSorage()) {
      this.profilePic = this.getLogosFromSorage();
    } else {
      this.profilePic = "./assets/icons/user--1.png";
    }
  }

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
}

  setValues() {
    let user = this.getUserFromSorage();

    if (user) {
      this.token = user.token;
      this.name = user.name;
      this.role = user.role;
      this.id = user.id;
      if (user.appAccess) {
        try {

          this.appAccess = JSON.parse(user.appAccess);
        } catch (e) {

        }
      }
      if (user.appAccessSO) {
        try {

          this.appAccessSO = JSON.parse(user.appAccessSO);
        } catch (e) {

        }
      }
    }
  }
  logout() {


    // if (!environment.production) {
    localStorage.clear();
    // }
    this.token = null;
    this.name = null;
    this.role = null;
    this.id = null;
    // this._router.navigate(['']);

    this._router.navigate(['']).then(() => {
      window.location.reload();
    });
    // this._router.navigateByUrl('', { skipLocationChange: true }).then(() => {
    //   this._router.navigate(['']);
    // });
  }

  checkAccess(access) {
    try {
      if (this.appAccess && this.appAccess.length > 0) {
        return this.appAccess.find(obj => obj.menu_name == access).access_flag == 'Y' ? true : false;
      }
      return false;
    } catch (e) {

    }

  }
  hideTabs(menu_name, so_name, title) {
    if (this.appAccessSO) {

      return this.appAccessSO.find(obj => obj.menu_name == menu_name && obj.so_name == so_name).configuration == "[\"N\"]" ? false : true;
    }
  }
  checkSOAccess(access) {
    if (this.appAccessSO && this.appAccessSO.length > 0) {

      return this.appAccessSO.find(obj => obj.so_name == access).configuration == "[Y]" ? true : false;
    }
    return false;
  }
  onFirstComponentButtonClick(name: any) {
    this.invokeFirstComponentFunction.emit(name);
  }

  dateConverter(dateString) {
    if (dateString) {
      let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString, "DD-MM-YYYY hh:mm:ss").format('YYYY-MM-DD');
      // var date = new Date(dateString);

      return parsedDate;//this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }
  dateConverter2(dateString) {
    if (dateString) {
      let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString, "YYYY-MM-DD").format('MMM-YYYY');
      // var date = new Date(dateString);

      return parsedDate;//this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }
  dateConverter1(dateString) {
    if (dateString) {
      let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString, "YYYY-MM-DD").format('DD-MM-YYYY');
      // var date = new Date(dateString);

      return parsedDate;//this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }

  dateConverterExcel(dateString) {
    if (dateString) {
      let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString, "YYYY-MM-DD").format('DD-MM-YYYY');
      // let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString, "YYYY-MM-DD").toDate();
      // var date = new Date(dateString);

      return parsedDate;//this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }

  dateConverterMMDDYYYY(dateString) {
    if (dateString) {
      let parsedDate = (dateString == null || dateString == '') ? '' : moment(dateString).format('MM-DD-YYYY');
      // var date = new Date(dateString);
      console.log("Date======", dateString, parsedDate);

      return parsedDate;//this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }
  getTemplateCopied() {
    return this.storageService.secureStorage.getItem("template");

  }
  getLogosFromSorage() {
    return this.storageService.secureStorage.getItem("profilelogo");
  }
  savelogosToSorage(user: any) {
    if (this.loggedIn())
      this.storageService.secureStorage.setItem("profilelogo", user);
  }
  getCompanyLogosFromSorage() {
    return this.storageService.secureStorage.getItem("logo");
  }
  saveCompanylogosToSorage(user: any) {
    this.storageService.secureStorage.setItem("logo", user);
  }
  saveUserinfoToSorage(user: any) {
    this.storageService.secureStorage.setItem("userinfo", user);
  }
  getUserInfoSorage() {
    return this.storageService.secureStorage.getItem("userinfo");
  }
  saveUserToSorage(user: any) {
    this.storageService.secureStorage.setItem("user", user);
  }

  getUserFromSorage() {
    return this.storageService.secureStorage.getItem("user");
  }


  convertBase64ToBlobData(
    base64Data: string,
    contentType: string = "image/png",
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  attachmentDownload(base64content: string, contentType: string, name: string) {
    const blobData = this.convertBase64ToBlobData(base64content, contentType);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE
      window.navigator.msSaveOrOpenBlob(blobData, name);
    } else {
      // chrome
      const blob = new Blob([blobData], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.click();
    }
  }
  showCustom() {

  }
  showalert(message: string, type: string) {

    switch (type) {
      case 'success':
        this.toastr.successToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;
      case 'info':
        this.toastr.infoToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;
      case 'warnings':
          this.toastr.errorToastr(message, "", {
            position: "top-full-width",
            animate: "slideFromTop",
            toastTimeout: 60000,
            showCloseButton: true,
            enableHtml: true
  
          });
          break;

      default:
        this.toastr.errorToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;
        
    }
  }
  showFirebaseNotification(title: string, message: string,_paylod:any) {
    this._eventService.notificationAdded.next(_paylod);

    this.toastr.infoToastr(title.replace(/<\/?[^>]+(>|$)/g, ""), message.replace(/<\/?[^>]+(>|$)/g, ""), {
      position: "bottom-right",
      animate: "slideFromBottom",
      toastTimeout: 7000,
    });
    
  }
  parseDate(date: string) {
    let parsedDate = (date == null || date == '') ? '' : moment(date, "YYYY-MM-DD hh:mm:ss").format('DD-MM-YYYY');


    return parsedDate;
  }
  parseDateDDMMYYY(date: string) {
    let parsedDate = (date == null || date == '') ? '' : moment(date);


    return parsedDate;
  }
  getAccess(list: any[], key: string) {
    return list.find((item) => {
      return item.menu_name === key;
    })
  }

  loginUser(data: any) {
    return this.http.post<any>(`${environment.api}/login`, data);
  }

  sendResetPasswordLink(data: any) {
    return this.http.post<any>(
      `${environment.api}/send-reset-password-link`,
      data
    );
  }

  setNewPassword(data: any) {
    return this.http.post<any>(`${environment.api}/set-new-password`, data);
  }
  getAccessData(data: any) {
    return this.http.post<any>(`${environment.api}/getAccessData`, data);
  }
  loggedIn() {
    return this.token != null;
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    this.token = null;
  }

  getCompanies(data: any) {
    return this.http.post<any>(`${environment.api}/get-companies`, data);
  }
  changeCompanyStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/change-company-status`,
      data
    );
  }
  getProjects(data: any) {
    return this.http.post<any>(`${environment.api}/get-projects`, data);
  }
  getUnApprovedProjects(data: any) {
    return this.http.post<any>(`${environment.api}/get-unapproved-projects`, data);
  }
  getTorList(data: any) {
    return this.http.post<any>(`${environment.api}/getTorList`, data);
  }
  getTorListForProjectInitiation(data: any) {
    return this.http.post<any>(`${environment.api}/getTorListForProjectInitiation`, data);
  }
  getProjectMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-master`, data);
  }
  // 
  getOnbehalfProjectEmployeeMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-onbehalf-project-employee-master`, data);
  }
  getDropDownProjectMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-drop-down-project-master`, data);
  }
  getProjectSummaryProjectMaster(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-summary-project-master`,
      data
    );
  }
  getProjectSummaryProjects(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-summary-projects`,
      data
    );
  }
  getCompaniesList(data: any) {
    return this.http.post<any>(`${environment.api}/get-companies-master`, data);
  }
  getCompaniesLists(data: any) {
    return this.http.post<any>(`${environment.api}/get-companies-masters`, data);
  }
  deleteProjectDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-project-document`,
      data
    );
  }

    deleteProjectTaskDocument(data: any) { 
    return this.http.post<any>(
      `${environment.api}/delete-task-project-document`,
      data
    );
  }
  deleteMaster(data: any) {
    return this.http.post<any>(`${environment.api}/delete-master`, data);
  }
  deleteReleaseNote(data: any) {
    return this.http.post<any>(`${environment.api}/delete-release-note`, data);
  }
  getProjectType(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-type-master`,
      data
    );
  }
  saveNote(data:any){
    return this.http.post<any>(
      `${environment.api}/saveNote`,
      data
    );
  }
  getNote(data:any){
    return this.http.post<any>(
      `${environment.api}/getNote`,
      data
    );
  }

  sendBirthdayWish(data: any) {
    return this.http.post<any>(`${environment.api}/sendBirthdayWish`, data);
  }
  // get-activity-status-for-projects
  getActivityStatusForProjects(data: any) {
    return this.http.post<any>(`${environment.api}/get-activity-status-for-projects`, data);
  }
  getStatuslist(data: any) {
    return this.http.post<any>(`${environment.api}/get-status-master`, data);
  }

  getMilestoneAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-milestone-analysis`,
      data
    );
  }

  getMilestoneActivityAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-milestone-activity-analysis`,
      data
    );
  }

  getActivityStatusAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-status-analysis`,
      data
    );
  }
  addTemplateTaskDependancy(data) {
    return this.http.post<any>(
      `${environment.api}/addTemplateTaskDependancy`,
      data
    );
  }

  getActivityDistribution(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-distribution`,
      data
    );
  }

  getTeamwiseBucketAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-teamwise-bucket-analysis`,
      data
    );
  }

  getTaskList(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-list`, data);
  }

  getchartTaskList(data: any) {
    return this.http.post<any>(`${environment.api}/get-chart-task-list`, data);
  }

  getTopOverdueActivity(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-top-overdue-activities`,
      data
    );
  }
  getProjectDelays(data: any) {
    return this.http.post<any>(
      `${environment.api}/getProjectDelays`,
      data
    );
  }
  getMilestoneActivityStatusAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-milestone-activity-status-analysis`,
      data
    );
  }

  addProject(data: any) {
    return this.http.post<any>(`${environment.api}/add-project`, data);
  }

  addTimeSheet(data: any) {
    return this.http.post<any>(`${environment.api}/add-time-sheet`, data);
  }
  addTimeSheetComment(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-time-sheet-comment`,
      data
    );
  }
  changePassword(data: any) {
    return this.http.post<any>(`${environment.api}/change-password`, data);
  }

  getTeamStatus(data: any) {
    return this.http.post<any>(`${environment.api}/get-team-status`, data);
  }

  getEmployeeList(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-master`, data);
  }
  getEmployeeAllList(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-all-master`, data);
  }
  getAdminlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-admin-list`, data);
  }

  getProjectEmployeeList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-employee-master`,
      data
    );
  }

  getmommilestoneList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-milestone-list`,
      data
    );
  }

  getTimesheetAndEmployeList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-timesheet-employee-master`,
      data
    );
  }

  getTimesheetAndEmployeListForChart(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-timesheet-employee-master-chart`,
      data
    );
  }

  getMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-master`, data);
  }

  addTaskList(data: any) {
    return this.http.post<any>(`${environment.api}/add-task-list`, data);
  }

  addMilestone(data: any) {
    return this.http.post<any>(`${environment.api}/add-milestone`, data);
  }
  addMaster(data: any) {
    return this.http.post<any>(`${environment.api}/add-master`, data);
  }
  addReleaseNotes(data: any) {
    return this.http.post<any>(`${environment.api}/add-release-note`, data);
  }

  getTaskTree(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-tree`, data);
  }
  getGanttTaskTree(data: any) {
    return this.http.post<any>(`${environment.api}/getGanttTaskTree`, data);
  }
  updateCompany(data: any) {
    return this.http.post<any>(`${environment.api}/update-company`, data);
  }
  updateMaster(data: any) {
    return this.http.post<any>(`${environment.api}/update-master`, data);
  }
  updateReleaseNote(data: any) {
    return this.http.post<any>(`${environment.api}/update-release-note`, data);
  }

  getCompany(data: any) {
    return this.http.post<any>(`${environment.api}/get-company`, data);
  }

  getProject(data: any) {
    return this.http.post<any>(`${environment.api}/get-project`, data);
  }
getTorDetails(data: any) {
  return this.http.post<any>(`${environment.api}/getTorDetails`, data);
}
  approveOrRejectTor(data: any) {
    return this.http.post<any>(`${environment.api}/approveOrRejectTor`, data);
  }
  getMyWorkbench(data: any) {
    return this.http.post<any>(`${environment.api}/get-my-workbench`, data);
  }
  //
  getRecentActivities(data: any) {
    return this.http.post<any>(`${environment.api}/get-recentActivities`, data);
  }
  getRecentBirthdays(data: any) {
    return this.http.post<any>(`${environment.api}/get-recentBirthdays`, data);
  }

  getMyWorkbenchAll(data: any) {
    return this.http.post<any>(`${environment.api}/get-my-workbench-all`, data);
  }

  getMyWorkbenchKpisAndTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-workbench-kpis-and-tasks`,
      data
    );
  }

  getMyWorkbenchTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-workbench-tasks`,
      data
    );
  }

  getMyFollowUp(data: any) {
    return this.http.post<any>(`${environment.api}/get-my-followup`, data);
  }
  getMyFollowupKpisAndTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-followup-kpis-and-tasks`,
      data
    );
  }
  getMyFollowupTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-followup-tasks`,
      data
    );
  }
  getMyTeamTracking(data: any) {
    return this.http.post<any>(`${environment.api}/get-my-team-tracking`, data);
  }
  getMyTrakingEmployee(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-team-tracking-employees-kpis-and-tasks`,
      data
    );
  }
  getMyTrakingEmployeeKpisAndTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-team-tracking-kpis-and-tasks`,
      data
    );
  }
  getMyTrakingEmployeeTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-team-tracking-tasks`,
      data
    );
  }

  getProjectMembers(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-members`, data);
  }
  getProjectMembersForActivity(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-members-for-activity`, data);

  }
  getTimesheetActivitiesForDay(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-timesheet-activities-for-day`,
      data
    );
  }

  getActivityLogDetails(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-log-details`,
      data
    );
  }

  getTimeSheetForMonth(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-time-sheet-for-month`,
      data
    );
  }

  getActivityFilledTimesheet(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-filled-timesheet`,
      data
    );
  }

  deleteTimesheetDocument(data: any) {
    return this.http.post<any>(`${environment.api}/delete-timesheet-document`, data);
  }


  updateProjet(data: any) {
    return this.http.post<any>(`${environment.api}/update-project`, data);
  }

  getDelete(data: any) {
    return this.http.post<any>(`${environment.api}/delete-company`, data);
  }

  deleteAccessLevel(data: any) {
    return this.http.post<any>(`${environment.api}/deleteAccessLevel`, data);
  }
  deleteTaskList(data: any) {
    return this.http.post<any>(`${environment.api}/delete-task-list`, data);
  }
  deleteMilestone(data: any) {
    return this.http.post<any>(`${environment.api}/delete-milestone`, data);
  }

  deleteProject(data: any) {
    return this.http.post<any>(`${environment.api}/delete-project`, data);
  }
  projectInitiation(data: any) {
    return this.http.post<any>(`${environment.api}/projectInitiation`, data);
  }
  approveProject(data: any) {
    return this.http.post<any>(`${environment.api}/approve-project`, data);
  }

  rollBackRecord(data: any) {
    return this.http.post<any>(`${environment.api}/rollback-record`, data);
  }
  bulkProjectActivityDeletion(data: any) {
    return this.http.post<any>(`${environment.api}/bulkActivityDelete`, data);
  }
  permanentDeleteRecord(data: any) {
    return this.http.post<any>(`${environment.api}/permanent-delete-record`, data);
  }

  addCompany(data: any) {
    return this.http.post<any>(`${environment.api}/add-company`, data);
  }
  getRoles(data: any) {
    return this.http.post<any>(`${environment.api}/get-role-master`, data);
  }
  addUser(data: any) {
    return this.http.post<any>(`${environment.api}/add-employee`, data);
  }
  addGroup(data: any) {
    return this.http.post<any>(`${environment.api}/add-group`, data);
  }
  getGroupList(data: any) {
    return this.http.post<any>(`${environment.api}/get-group-list`, data);
  }
  deleteGroup(data: any) {
    return this.http.post<any>(`${environment.api}/delete-group-one`, data);
  }

  addGroupMember(data: any) {
    return this.http.post<any>(`${environment.api}/add-group-member`, data);
  }

  getGroupOne(data: any) {
    return this.http.post<any>(`${environment.api}/get-group-list-one`, data);
  }
  getGroupUserList(data: any) {
    return this.http.post<any>(`${environment.api}/get-group-member`, data);
  }

  updateGroupOne(data: any) {
    return this.http.post<any>(`${environment.api}/update-group-one`, data);
  }

  deleteGroupOne(data: any) {
    return this.http.post<any>(`${environment.api}/delete-group-member`, data);
  }


  editEmployeePersonalInfo(data: any) {
    return this.http.post<any>(`${environment.api}/edit-employee-personal-info`, data);
  }
  editEmployeeOfficialInfo(data: any) {
    return this.http.post<any>(`${environment.api}/edit-employee-official-info`, data);
  }
  editEmployeeSocialLinks(data: any) {
    return this.http.post<any>(`${environment.api}/edit-employee-social-links`, data);
  }
  addEmployeeProfileInfo(data: any) {
    return this.http.post<any>(`${environment.api}/add-employee-Profile-info`, data);
  }
  editEmployeeProfileInfo(data: any) {
    return this.http.post<any>(`${environment.api}/edit-employee-profile-info`, data);
  }
  deleteEmployeeProfileInfo(data: any) {
    return this.http.post<any>(`${environment.api}/delete-employee-profile-info`, data);
  }
  getUserlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-employees`, data);
  }
  getEmpUserlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-emp-employees`, data);
  }

  getTOREmpEmployees(data: any) {
    return this.http.post<any>(`${environment.api}/get-tor-emp-employees`, data);
  }

  addHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/add-holiday`, data);
  }
  getholidayList(data: any) {
    return this.http.post<any>(`${environment.api}/get-holidays`, data);
  }
  getHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/get-holiday`, data);
  }
  getMasterlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-master`, data);
  }
  getReleaseHistoryList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-release-history-list`,
      data
    );
  }
  getEmployee(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee`, data);
  }
  getEmployeeDetail(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-detail`, data);
  }
  getEmployeeFamily(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-family`, data);
  }
  getEmployeeActivity(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-activities`, data);
  }
  getEmployeeAcademics(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-academics`, data);
  }
  getEmployeeExperiences(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-experiences`, data);
  }
  getEmployeeSkills(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-skills`, data);
  }
  getDeleteUser(data: any) {
    return this.http.post<any>(`${environment.api}/delete-employee`, data);
  }
  changeEmployeeStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/change-employee-status`,
      data
    );
  }
  updateUser(data: any) {
    return this.http.post<any>(`${environment.api}/update-employee`, data);
  }
  updateHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/update-holiday`, data);
  }
  getDeleteHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/delete-holiday`, data);
  }

  addTask(data: any) {
    return this.http.post<any>(`${environment.api}/add-task`, data);
  }
  updateTask(data: any) {
    return this.http.post<any>(`${environment.api}/update-task`, data);
  }

  getDeleteTask(data: any) {
    return this.http.post<any>(`${environment.api}/delete-task`, data);
  }
  getMilestone(data: any) {
    return this.http.post<any>(`${environment.api}/get-milestones`, data);
  }
  // syncMail(data: any) {
  //   return this.http.get<any>(`${environment.api}/qstart`, data);
  // }
  getActivityType(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-type-master`,
      data
    );
  }
  getTasklist(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-lists`, data);
  }
  uploadProjectDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/upload-project-document`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  uploadUserImage(data: any) {
    return this.http.post<any>(`${environment.api}/upload-user-profile`, data, {
      reportProgress: true,
      observe: "events",
    });
  }
  getProjectDocument(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-document`, data);
  }
  uploadTimesheetDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/upload-timesheet-document`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  uploadProfilePic(data: any) {
    return this.http.post<any>(`${environment.api}/upload-profile-pic`, data, {
      reportProgress: true,
      observe: "events",
    });
  }

  getProfilePicture(data: any) {
    return this.http.post<any>(`${environment.api}/get-profile-pic`, data);
  }
  //KD -: for Profile icons and company icons
  uploadProfileAttachments(data: any) {
    return this.http.post<any>(
      `${environment.api}/upload-profile-document`,
      data
    );
  }
  updateProfileAttachments(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-profile-document`,
      data
    );
  }
  getProfilePic() {
    return this.http.post<any>(`${environment.api}/get-profile-logo`, "");
  }
  getCompanyLogo() {
    return this.http.post<any>(`${environment.api}/get-company-logo`, "");
  }
  getMyForum(data: any) {
    return this.http.post<any>(`${environment.api}/get-my-forum`, data);
  }
  getforum(data:any){
    return this.http.post<any>(`${environment.api}/getforum`, data);

  }
  getForumData(data: any) {
    return this.http.post<any>(`${environment.api}/get-forum-data`, data);
  }
  getForumMembers(data: any) {
    return this.http.post<any>(`${environment.api}/get-forum-members`, data);
  }
  addNewForum(data: any) {
    return this.http.post<any>(`${environment.api}/add-new-forum`, data);
  }
  deletePost(data: any) {
    return this.http.post<any>(`${environment.api}/delete-post`, data);
  }
  deleteForum(data: any) {
    return this.http.post<any>(`${environment.api}/delete-forum`, data);
  }

  deleteForumMember(data: any) {
    return this.http.post<any>(`${environment.api}/delete-forum-member`, data);
  }
  markAsSeen(data: any) {
    return this.http.post<any>(`${environment.api}/mark-post-as-seen`, data);
  }

  getRemainingForumMembers(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-remaining-forum-members`,
      data
    );
  }
  addNewMemberToForum(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-new-member-toForum`,
      data
    );
  }
  ForumPostLike(data: any) {
    return this.http.post<any>(`${environment.api}/forum-post-like`, data);
  }
  getLikePostCount(data: any) {
    return this.http.post<any>(`${environment.api}/get-like-post-count`, data);
  }
  addPostToForum(data: any) {
    return this.http.post<any>(`${environment.api}/add-post-to-forum`, data);
  }
  markForumPostAsStar(data: any) {
    return this.http.post<any>(`${environment.api}/markForumPostAsStar`, data);
  }
  pinForum(data:any){
    return this.http.post<any>(`${environment.api}/pinForum`, data);
      
  }
  //
  uploadPostAttachments(data: any) {
    return this.http.post<any>(
      `${environment.api}/upload-post-attachments`,
      data
    );
  }

  getPostDocument(data: any) {
    return this.http.post<any>(`${environment.api}/get-post-document`, data);
  }

  getForumPost(data: any) {
    return this.http.post<any>(`${environment.api}/get-forum-post`, data);
  }

  getActivityStatus(data: any) {
    return this.http.post<any>(`${environment.api}/get-status-master`, data);
  }
  getActivityAssignBy(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-master`, data);
  }
  getCategoryBy(data: any) {
    return this.http.post<any>(`${environment.api}/get-category-master`, data);
  }
  getActivityAssignTo(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-master`, data);
  }
  getVisibility(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-visibility-master`,
      data
    );
  }
  addActivity(data: any) {
    return this.http.post<any>(`${environment.api}/add-activity`, data);
  }
  addDependency(data: any) {
    return this.http.post<any>(`${environment.api}/add-update-dependency`, data);
  }
  addTemplateDependency(data: any) {
    return this.http.post<any>(`${environment.api}/add-update-template-dependency`, data);
  }
  getDependentTasksLists(data: any) {
    return this.http.post<any>(`${environment.api}/get-Project-Tasks-Except-Dependent`, data);
  }

  getTemplateDependentTasksLists(data: any) {
    return this.http.post<any>(`${environment.api}/get-template-Tasks-Except-Dependent`, data);
  }
  // 
  getActivityDependancy(data: any) {
    return this.http.post<any>(`${environment.api}/get-Activity-Dependancy`, data);
  }
  getTemplateActivityDependancy(data: any) {
    return this.http.post<any>(`${environment.api}/get-Template-Activity-Dependancy`, data);
  }
  removeDependency(data: any) {
    return this.http.post<any>(`${environment.api}/remove-dependency`, data);
  }
  circularDependency(data: any) {
    return this.http.post<any>(`${environment.api}/circularDependency`, data);
  }
  removeTemplateDependency(data: any) {
    return this.http.post<any>(`${environment.api}/remove-template-dependency`, data);
  }

  quickAddActivity(data: any) {
    return this.http.post<any>(`${environment.api}/quick-add-activity`, data);
  }
  getAcitivity(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-tree`, data);
  }

  getApplicationAccessLevel(data: any) {
    return this.http.post<any>(`${environment.api}/getAccessLevelTree`, data);
  }
  getAccessLevelData(data: any) {
    return this.http.post<any>(`${environment.api}/getAccessLevels`, data);
  }
  getProjectAccessLevelTree(data: any) {
    return this.http.post<any>(`${environment.api}/getAccessLevelTree`, data);
  }

  getAccessLevelConfiguration(data: any) {
    return this.http.post<any>(`${environment.api}/getAccessLevelConfiguration`, data);
  }

  addAccessLevel(data: any) {
    return this.http.post<any>(`${environment.api}/addAccessLevel`, data);
  }

  updateAccessLevel(data: any) {
    return this.http.post<any>(`${environment.api}/updateAccessLevel`, data);
  }
  getTsklistdata(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-lists`, data);
  }
  updateTaskActivities(data: any) {
    return this.http.post<any>(`${environment.api}/update-activity`, data);
  }
  updateRecurringSchedule(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-recurring-schedule`,
      data
    );
  }
  getEmployeeMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-master`, data);
  }

  getAnalyticsEmployeeMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-analytics-employee-master`, data);
  }

  getRemainingEmployeeMaster(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-remaining-employee-master`,
      data
    );
  }

  getRoleMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-role-master`, data);
  }
  getMemberData(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-members`, data);
  }
  getMilestoneData(data: any) {
    return this.http.post<any>(`${environment.api}/get-milestones`, data);
  }

  updateMilestone(data: any) {
    return this.http.post<any>(`${environment.api}/update-milestone`, data);
  }

  updateTaskList(data: any) {
    return this.http.post<any>(`${environment.api}/update-task-list`, data);
  }

  updateGroupAccessLevel(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-group-access-level`,
      data
    );
  }
  updateMembers(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-member`,
      data
    );
  }
  //add-project-groups
  //
  getProjectGroupsAccess(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-groups-access`, data);
  }


  addGroups(data: any) {
    return this.http.post<any>(`${environment.api}/add-project-groups`, data);
  }
  addMembers(data: any) {
    return this.http.post<any>(`${environment.api}/add-project-member`, data);
  }

  deleteGroupAccess(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-group-access`,
      data
    );
  }
  deletemember(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-project-member`,
      data
    );
  }
  deleteActivity(data: any) {
    return this.http.post<any>(`${environment.api}/delete-activity`, data);
  }
  getAttachements(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-documents`,
      data
    );
  }
  getActivityAttachements(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-documents`,
      data
    );
  }
  getActivityDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-activity-details`, data);
  }

  getLogActivityDetails(data: any) {
    return this.http.post<any>(`${environment.api}/getLogActivityDetails`, data);
  }
  //get-recurring-schedule
  getRecurringSchedule(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-recurring-schedule`,
      data
    );
  }

  getActivityDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-activity-document`,
      data
    );
  }
  deleteActivityDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-activity-document`,
      data
    );
  }
  uploadActivityDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/upload-activity-document`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  uploadTemplateDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/uploadTemplateDocument`,
      data,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }
  getTimesheetDocument(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-timesheet-document`,
      data
    );
  }
  getEmployeeReports(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-report`, data);
  }
  getEmployeePerticularReports(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-perticular-report`,
      data
    );
  }
  getEmployeeTimeSheetReports(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-timesheet-report`,
      data
    );
  }
  getEmployeeProjectStatusReport(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-project-status-report`,
      data
    );
  }
  getEmployeeTaskReport(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-TaskReport`,
      data
    );
  }
  getProjectCategoryReport(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-Project-Category-Report`,
      data
    );
  }
  getEmployeeWeeklyReports(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-weekly-report`,
      data
    );
  }
  getEmployeeEngagmentReports(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-engagment-report`,
      data
    );
  }
  
  getEmployeeTotalDaysCount(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-totalTime`,
      data
    );
  }
  getEmployeeTotalDays(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-totalDays`,
      data
    );
  }
  getEmployeeYearlyReports(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-yearlyReport`,
      data
    );
  }
  getEmployeeYearTaskReport(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-Project-yearly-task-Report`,
      data
    );
  }
  getEmployeeMonthTaskReport(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-Project-monthly-task-Report`,
      data
    );
  }
  getCategoryList(data: any) {
    return this.http.post<any>(`${environment.api}/get-category-master`, data);
  }
  getBusinessBenifitlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-benefit-master`, data);
  }
  getGovernanceStrategyConfiguration(data: any) {
    return this.http.post<any>(`${environment.api}/governance-strategy-configuration`, data);
  }
  getDepartmentlist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-department-master`,
      data
    );
  }
  getSubDepartmentlist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-sub-department-master`,
      data
    );
  }
  getMyDepartment(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-department-master`,
      data
    );
  }

  getAnalyticsDepatmentMaster(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-analytics-department-master`,
      data
    );
  }
  getProjectTypelist(data) {
    return this.http.post<any>(
      `${environment.api}/get-projecttype`, data

    );
  }
  getAnalyticsSubDepatmentMaster(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-analytics-subdepartment-master`,
      data
    );
  }
  getMyDepartmentlist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-department-master`,
      data
    );
  }

  getSectionData(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-section`, data);
  }

  getStatusData(data: any) {
    return this.http.post<any>(`${environment.api}/get-status-master`, data);
  }

  setStatusColor(data: any) {
    return this.http.post<any>(`${environment.api}/set-status-color`, data);
  }

  getIssueSevirity(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-task-issuesSevirity`,
      data
    );
  }
  getTasklistAndMilestone(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-milestoneTaskLists`,
      data
    );
  }
  getTaskProjectslist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-projects-tasklists`,
      data
    );
  }
  getSectionlist(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-section`, data);
  }
  addTemplateDependancy(data: any) {
    return this.http.post<any>(
      `${environment.api}/addTemplateDependancy`,
      data
    );
  }
  getProjectTemplateCategories(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-template-categories`,
      data
    );
  }
  getProjectTemplates(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-templates`,
      data
    );
  }
  updateFirebaseToken(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-firebase-token`,
      data
    );
  }
  getCountAndNotifications(data: any) {
    
    return this.http.post<any>(
      `${environment.api}/get-count-and-notifications`,
      data
    );
  }
  getCountsOfNotifications(data:any){
    return this.http.post<any>(
      `${environment.api}/getCountsOfNotifications`,
      data
    );
  }
  getCountAndNotificationsnew(data: any) {
    
    return this.http.post<any>(
      `${environment.api}/get-count-and-notifications`,
      data
    );
  }
  getNotificationOnType(data: any) {
    
    return this.http.post<any>(
      `${environment.api}/getNotificationOnType`,
      data
    );
  }
  getStarPosts(data: any) {
    return this.http.post<any>(
      `${environment.api}/getStarPosts`,
      data
    );
  }
  checkForReleaseNote(data: any) {
    return this.http.post<any>(`${environment.api}/release-note`, data);
  }
  updateNotificationSeen(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-notification-seen`,
      data
    );
  }
  updateNotificationSeenAll(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-notification-seen-all`,
      data
    );
  }

  getEmployeeTimeSheet(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-timesheet`,
      data
    );
  }

  getEmployeeTimesheetActivitiesForDay(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-employee-timesheet-activities-for-day`,
      data
    );
  }

  markHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/mark-holiday`, data);
  }
  deleteMarkHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/delete-mark-holiday`, data);
  }

  getMarkHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/get-mark-holiday`, data);
  }

  getAllMarkHoliday(data: any) {
    return this.http.post<any>(`${environment.api}/get-all-mark-holiday`, data);
  }

  saveHolidayConfirmation(data: any) {
    return this.http.post<any>(
      `${environment.api}/save-holiday-confirmation`,
      data
    );
  }

  updateHolidayConfirmation(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-holiday-confirmation`,
      data
    );
  }

  getHolidayConfig(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-holiday-confirmation`,
      data
    );
  }

  //getProjectActivityAnalysis

  getProjectMilestoneActivityAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-activity-analysis`,
      data
    );
  }
  getProjectActivityStatusAnalysis(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-status-analysis`,
      data
    );
  }

  deleteLogApi(data: any) {
    return this.http.post<any>(`${environment.api}/delete-activity-log`, data);
  }

  updateTimeSheet(data: any) {
    return this.http.post<any>(`${environment.api}/update-activity-log`, data);
  }

  //template master

  addTemplate(data: any) {
    return this.http.post<any>(`${environment.api}/add-template`, data);
  }

  editTemplate(data: any) {
    return this.http.post<any>(`${environment.api}/update-template`, data);
  }

  deleteTemplate(data: any) {
    return this.http.post<any>(`${environment.api}/delete-template`, data);
  }

  getcategoryList(data: any) {
    return this.http.post<any>(`${environment.api}/get-category-list`, data);
  }

  getcategoryforBookmarkList(data: any) {
    return this.http.post<any>(`${environment.api}/getcategoryforBookmarkList`, data);
  }

  getTemplateDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-template-detail`, data);
  }

  getcategoryTemplate(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-category-template`,
      data
    );
  }

  getTemplateMaster(data: any) {
    return this.http.post<any>(`${environment.api}/get-template`, data);
  }

  // template milestone

  addTemplatemilestone(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-milestone`,
      data
    );
  }

  updateTemplatemilestone(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-milestone`,
      data
    );
  }

  deleteTemplatemilestone(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-template-milestone`,
      data
    );
  }

  getTemplateMilestone(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-milestone`,
      data
    );
  }

  // template tasklist

  addTemplatetasklist(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-tasklist`,
      data
    );
  }

  updateTemplatetasklist(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-tasklist`,
      data
    );
  }

  deleteTemplatetasklist(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-template-tasklist`,
      data
    );
  }

  getTemplatetasklist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-tasklist`,
      data
    );
  }

  // template task

  addTemplatetask(data: any) {
    return this.http.post<any>(`${environment.api}/add-template-task`, data);
  }
  getTemplatetaskdetails(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-task-details`,
      data
    );
  }


  editTimeSheetComment(data: any) {
    return this.http.post<any>(`${environment.api}/edit-time-sheet-comment`, data);
  }

  deleteTemplatetask(data: any) {
    return this.http.post<any>(`${environment.api}/delete-template-task`, data);
  }

  updateTemplatetask(data: any) {
    return this.http.post<any>(`${environment.api}/update-template-task`, data);
  }

  getTemplatetasktree(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-task-tree`,
      data
    );
  }
  //
  getStatusIds(data: any) {
    return this.http.post<any>(`${environment.api}/get-status-ids`, data);
  }
  getStatusList(data: any) {
    return this.http.post<any>(`${environment.api}/get-tran-status`, data);
  }

  addTranStatus(data: any) {
    return this.http.post<any>(`${environment.api}/add-tran-status`, data);
  }

  updateTranStatus(data: any) {
    return this.http.post<any>(`${environment.api}/update-tran-status`, data);
  }

  deleteTranStatus(data: any) {
    return this.http.post<any>(`${environment.api}/delete-tran-status`, data);
  }

  updateDefaultVal(data: any) {
    return this.http.post<any>(`${environment.api}/update-tran-default-val`, data);
  }
  // project status master
  //get-status-against-project
  getStatusAgainstProject(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-status-against-project`,
      data
    );
  }
  getprojectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-status`,
      data
    );
  }
  getprojectstatusList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-status-List`,
      data
    );
  }

  addProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-project-status`,
      data
    );
  }

  updateProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-status`,
      data
    );
  }

  deleteProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-project-status`,
      data
    );
  }

  updateProjectDefaultStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-default-status`,
      data
    );
  }


  // project Activity status master

  getProjectactivitystatusList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-activity-status-List`,
      data
    );
  }

  addProjectactivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-project-activity-status`,
      data
    );
  }

  updateProjectactivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-status`,
      data
    );
  }

  deleteProjectactivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-project-activity-status`,
      data
    );
  }

  updateProjectActivityDefaultStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-default-status`,
      data
    );
  }

  // template project status

  addTemplateProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-project-status`,
      data
    );
  }

  addTemplateSingleprojectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-single-project-status`,
      data
    );
  }

  getTemplateProjectstatusList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-project-status`,
      data
    );
  }
  getTemplateEfforts(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-efforts`,
      data
    );
  }
  getTemplateSLAEfforts(data: any) {
    return this.http.post<any>(
      `${environment.api}/getTemplateSLAEfforts`,
      data
    );
  }
  calculateDaysBetweenWorkingHours(data) {
    return this.http.post<any>(
      `${environment.api}/calculateDaysBetweenWorkingHours`,
      data
    );
  }
  calculateHoursFromDates(data) {
    return this.http.post<any>(
      `${environment.api}/calculate-hours-based-on-start-end-date`,
      data
    );
  }
  deleteTemplateProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-template-project-status`,
      data
    );
  }

  updateTemplateProjectstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-project-status`,
      data
    );
  }

  updateTemplateProjectDefaultstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-project-default-status`,
      data
    );
  }


  // template activity status

  addTemplateActivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-project-activity-status`,
      data
    );
  }


  addTemplateSingleactivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-single-project-activity-status`,
      data
    );
  }

  getTemplateProjectactivitystatusList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-project-activity-status`,
      data
    );
  }

  deleteTemplateActivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-template-project-activity-status`,
      data
    );
  }

  updateTemplateActivitystatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-project-activity-status`,
      data
    );
  }

  updateTemplateActivityDefaultstatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-project-activity-default-status`,
      data
    );
  }
  //
  removeFavProject(data: any) {
    return this.http.post<any>(`${environment.api}/remove-fav-projects`, data);
  }


  addFavProject(data: any) {
    return this.http.post<any>(`${environment.api}/add-fav-projects`, data);
  }
  // 
  getYear(data: any) {
    return this.http.post<any>(`${environment.api}/get-year`, data);
  }


  getSelectedProjectStatus(data: any) {
    return this.http.post<any>(`${environment.api}/get-selected-project-status`, data);
  }

  getMyFavProject(data: any) {
    return this.http.post<any>(`${environment.api}/getMyFavProject`, data);
  }
  // project activity and template activity sortorder

  updateProjectActivitysortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-sort-order`,
      data
    );
  }

  updateTemplateActivitysortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-activity-sort-order`,
      data
    );
  }



  updateProjectActivitysortIdAfterUpdate(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-sort-order-updated`,
      data
    );
  }

  updateProjectsortIdActivity(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-sort-order-activity`,
      data
    );
  }

  updateTemplateActivitysortIdAfterUpdate(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-activity-sort-order-updated`,
      data
    );
  }

  updateTemplatesortIdActivity(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-activity-sort-order-activity`,
      data
    );
  }

  updateMyWorkbenchTasksKanben(data: any) {
    return this.http.post<any>(`${environment.api}/update-my-workbench-tasks`, data);
  }

  getMilestonesData(data: any) {
    return this.http.post<any>(`${environment.api}/get-milestones-data`, data);
  }

  getTaskListsData(data: any) {
    return this.http.post<any>(`${environment.api}/get-task-lists-data`, data);
  }

  getKanbanKpisAndTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-my-kanban-tasks`,
      data
    );
  }


  getKanbanKPICount(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-kanban-count`,
      data
    );
  }

  getProjectKanbanTasklist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-kanban-tasklist`,
      data
    );
  }

  getProjectKanbanTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-kanban-task`,
      data
    );
  }

  getProjectsingleKanbanTasks(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-single-kanban-tasklist`,
      data
    );
  }

  getProjectData(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-data`, data);
  }

  getViewMenu(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-view-menu`,
      data
    );
  }

  addTemplateViewMenu(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-view-menu`,
      data
    );
  }

  getTemplateViewMenu(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-view-menu`,
      data
    );
  }

  updateTemplateMenuDefaultFlag(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-menu-default-flag`,
      data
    );
  }

  getProjectViewMenu(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-view-menu`,
      data
    );
  }

  updateProjectMenuDefaultFlag(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-menu-default-flag`,
      data
    );
  }

  updateProjectViewssortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-views-sortid`,
      data
    );
  }

  updateTemplateViewssortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-views-sortid`,
      data
    );
  }

  updateTemplateactivitystatussortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-template-activity-status-sortid`,
      data
    );
  }

  updateProjectactivitystatussortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-activity-status-sortid`,
      data
    );
  }
updateProjectstatussortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-status-sortid`,
      data
    );
  }
  updatemasteractivitystatussortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-master-activity-status-sortid`,
      data
    );
  }

  updatemasterprojectstatussortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-master-project-status-sortid`,
      data
    );
  }

  sortTable(property, data) {
    this.isDesc = !this.isDesc; //change the direction
    let direction = this.isDesc ? 1 : -1;
    data.sort(function (a, b) {
      if (a[property] < b[property]) {

        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };


  getTemplateemployee(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-employee`,
      data
    );
  }

  addTemplateteamMember(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-template-team-member`,
      data
    );
  }

  deleteTemplateteamMember(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-template-team-member`,
      data
    );
  }

  getcompanyList(data: any) {
    return this.http.post<any>(`${environment.api}/get-company-list`, data);
  }
  UpdateOwnnerActivity(data: any) {
    return this.http.post<any>(`${environment.api}/update-ownner-activity`, data);
  }

  replaceTaskUser(data: any) {
    return this.http.post<any>(`${environment.api}/replace-task-user`, data);
  }
  getTemplateteamMember(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-template-team-member`,
      data
    );
  }
  getexportTaskTree(data: any) {
    return this.http.post<any>(`${environment.api}/get-export-task-tree`, data);
  }

  // 
  deletebulkProjects(data: any) {
    return this.http.post<any>(`${environment.api}/delete-bulk-projects`, data);
  }
  bulkActivityDelete(data: any) {
    return this.http.post<any>(`${environment.api}/bulkActivityDelete`, data);
  }
  deletebulkRollBackProjects(data: any) {
    return this.http.post<any>(`${environment.api}/delete-bulk-rollback-projects`, data);
  }
  
  permanantlydeletebulkProject(data: any) {
    return this.http.post<any>(`${environment.api}/permanantly-delete-bulk-projects`, data);
  }


  deleteFolder(data: any) {
    return this.http.post<any>(`${environment.api}/deleteFolder`, data);
  }
  deleteFolderAttachment(data: any) {
    return this.http.post<any>(
      `${environment.api}/deleteFolderAttachment`,
      data
    );
  }

  getparentFolder(data: any) {
    return this.http.post<any>(`${environment.api}/getparentFolder`, data);
    
  }

  getchildFolder(data: any) {
    return this.http.post<any>(`${environment.api}/getchildFolder`, data);
  }
  getAllFolderAttachment(data: any) {
    return this.http.post<any>(`${environment.api}/getAllFolderAttachment`, data);
  }
  getUsertDocument(data: any) {
    return this.http.post<any>(`${environment.api}/get-user-document`, data);
  }
  
  addFolder(data: any) {
    return this.http.post<any>(`${environment.api}/addFolder`, data);
  }
  editFolder(data: any) {
    return this.http.post<any>(`${environment.api}/editFolder`, data);
  }
  addfolderemployeeAccess(data: any) {
    return this.http.post<any>(`${environment.api}/addfolderemployeeAccess`, data);
  }

  getAllFolderDetails(data: any) {
    return this.http.post<any>(`${environment.api}/getAllFolderDetails`, data);
  }

  uploadFolderDocument(data: any) {
    return this.http.post<any>(`${environment.api}/uploadFolderDocument`, data, {
      reportProgress: true,
      observe: "events",
    });
  }
  editdocumentAttachment(data: any) {
    return this.http.post<any>(`${environment.api}/editdocumentAttachment`, data, {
      reportProgress: true,
      observe: "events",
    });
  }

  // 
  getUserListOfAccessLevel(data: any) {
    return this.http.post<any>(`${environment.api}/get-employee-list-of-access-level`, data);
  }
  getBookmarksList(data: any) {
    return this.http.post<any>(
      `${environment.api}/getAllImpLinkDetails`,
      data
    );
  }
  addBookMarkFile(data: any) {
    return this.http.post<any>(`${environment.api}/addImpLink`, data);
  }
  UpdateBookMark(data: any) {
    return this.http.post<any>(`${environment.api}/editImpLink`, data);
  }
  deleteBookmark(data: any) {
    return this.http.post<any>(`${environment.api}/deleteImpLink`, data);
  }


  

  getBaselineAllTasksForProject(data:any){
    return this.http.post<any>(
      `${environment.api}/getBaselineAllTasksForProject`,
      data
    );
  }

  createBaseLine(data:any){
    return this.http.post<any>(
      `${environment.api}/createBaseline`,
      data
    );
  }
  getBaselineForProject(data:any){
    return this.http.post<any>(
      `${environment.api}/getBaselineForProject`,
      data
    );
  }

  getmilestoneuserlist(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-milestone-user-list`,  data
      );
    }
  getmomList(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-list`,
      data
    );
  }

  addprojectMOM(data: any) {
    return this.http.post<any>(
      `${environment.api}/add-project-mom`,
      data
    );
  }

    getmomDetails(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-details`,
      data
    );
  }

  updateprojectMOM(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-mom`,
      data
    );
  }

    deleteprojectMOM(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-mom-point`,
      data
    );
  }
  // 
  getMosTypes(data:any){
    return this.http.post<any>(
      `${environment.api}/getMosTypes`,
      data
    );
  }
  addTOR(data:any){
    return this.http.post<any>(
      `${environment.api}/addTOR`,
      data
    );
  }
  updateTOR(data:any){
    return this.http.post<any>(
      `${environment.api}/updateTOR`,
      data
    );
  }

  deleteMOM(data: any) {
    return this.http.post<any>(
      `${environment.api}/delete-mom`,
      data
    );
  }

  getactivityRiskDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-activity-risk-detail`, data);
  }

  getactivityRiskList(data: any) {
    return this.http.post<any>(`${environment.api}/get-activity-Risk-List`, data);
  }

  updateactivityRisk(data: any) {
    return this.http.post<any>(`${environment.api}/update-activity-Risk-List`, data);
  }

  deleteactivityRiskList(data: any) {
    return this.http.post<any>(`${environment.api}/delete-activity-Risk-List`, data);
  }

  getprojectCharterDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-Charter-Details`, data);
  }

  updateprojectCharter(data: any) {
    return this.http.post<any>(`${environment.api}/update-project-Charter`, data);
  }

  getprojectsliderDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-slider-Details`, data);
  }
  getAllProjectsliderDetails(data: any) {
    return this.http.post<any>(`${environment.api}/get-bulk-project-slider-Details`, data);
  }

  insertprojectSliderDetails(data: any) {
    return this.http.post<any>(`${environment.api}/insert-project-slider-Details`, data);
  }

  deleteprojectActivities(data: any) {
    return this.http.post<any>(`${environment.api}/delete-project-activity-Details`, data);
  }

  getMOMTypeDetails(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-type-details`,
      data
    );
  }
  deleteprojectMilestone(data: any) {
    return this.http.post<any>(`${environment.api}/delete-project-milestone-Details`, data);
  }

  updateMilestonesortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-milestone-sortid`,
      data
    );
  }

  updateActivitysortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-activity-sortid`,
      data
    );
  }

  
  getMOMdateimeValid(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-date-valid`,
      data
    );
  }

  getCostHeader(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-cost-Header`,
      data
    );
  }

  getprojectCostHeaderData(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-cost-Header`,
      data
    );
  }

  getProjectHistory(data:any){
    return this.http.post<any>(
      `${environment.api}/getProjectHistory`,
      data
    );
  }

  getMOMAttendeesdateimeValid(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-mom-attendees-date-valid`,
      data
    );
  }

  addemailConfig(data:any){
    return this.http.post<any>(
      `${environment.api}/addemailConfig`,

      data
    );
  }

  getemailconfigList(data:any){
    return this.http.post<any>(
      `${environment.api}/getemailconfigList`,

      data
    );
  }

  getemailconfigDetails(data:any){
    return this.http.post<any>(
      `${environment.api}/getemailconfigDetails`,

      data
    );
  }

  updateemailConfig(data:any){
    return this.http.post<any>(
      `${environment.api}/updateemailConfig`,

      data
    );
  }

  deleteemailconfigDetails(data:any){
    return this.http.post<any>(
      `${environment.api}/deleteemailconfigDetails`,
      data
    );
  }

  getprojectemailconfigList(data:any){
    return this.http.post<any>(
      `${environment.api}/getprojectemailconfigList`,

      data
    );
  }

  deleteprojectemailconfigDetails(data:any){
    return this.http.post<any>(
      `${environment.api}/deleteprojectemailconfigDetails`,

      data
    );
  }

  updateprojectemailConfig(data:any){
    return this.http.post<any>(
      `${environment.api}/updateprojectemailConfig`,

      data
    );
  }

  getprojectemailconfigDetails(data:any){
    return this.http.post<any>(
      `${environment.api}/getprojectemailconfigDetails`,

      data
    );
  }

  getemailTemplateName(data:any){
    return this.http.post<any>(
      `${environment.api}/getemailTemplateName`,

      data
    );
  }

  addprojectemailConfig(data:any){
    return this.http.post<any>(
      `${environment.api}/addprojectemailConfig`,

      data
    );
  }

  getprojectsummarysDetails(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-project-summarys-Details`,
      data
    );
  }

  getRagStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-rag-status`,
      data
    );
  }

  checkProjectCompleteStatus(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-check-project-complete-status`,
      data
    );
  }

  updateUserAccessLevel(data: any) {
    return this.http.post<any>(
      `${environment.api}/updateUserAccessLevel`,
      data
    );
  }

  getTorDate(data: any) {
    return this.http.post<any>(
      `${environment.api}/getTorDate`,
      data
    );
  }
  mspImport(data:any){
    return this.http.post<any>(
      `${environment.api}/mspImport`,
      data
    );
  }

  // PMO
  getPMOTeamPerformance(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmo-team-performance-report`, data);
  }
  getPMOClosedProject(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmo-closed-project-report`, data);
  }
  getPMOProjectPlanAvailability(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmo-project-plan-availability-report`, data);
  }
  getPMOFunctionWise(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmo-function-wise-report`, data);
  }
  getPMOProjectPerformance(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmo-project-performanance-report`, data);
  }

  getprojectAcvitityCount(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-activity-count-report`, data);
  }

  getprojectperformanceReport(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-perfomance-wise-report`, data);
  }

  getprojectfuncationReport(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-function-wise-report`, data);
  }

  getprojectfuncationList(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-function-list`, data);
  }


  getprojectmanagerList(data: any) {
    return this.http.post<any>(`${environment.api}/get-project-manager-list`, data);
  }

  

  // PCR
  getPCR(data: any) {
    return this.http.post<any>(`${environment.api}/get-pcr`, data);
  }
  updatePCR(data: any) {
    return this.http.post<any>(`${environment.api}/update-pcr`, data);
  }
  getPCRMilestones(data: any) {
    return this.http.post<any>(`${environment.api}/getPCRMilestones`, data);
  }
  loginAd(data: any) {
    return this.http.post<any>(`${environment.api}/login-ad`, data);
  }
  //saml/samlLogout
  samlLogout() {
    return this.http.post<any>(`${environment.logoutapi}/saml/samlLogout`,{});
  }





  // getPCRMilestones(data: any) {
  //   return this.http.post<any>(`${environment.api}/getPCRMilestones`, data);
  // }
  // getPCR(data: any) {
  //   return this.http.post<any>(`${environment.api}/get-pcr`, data);
  // }
  // updatePCR(data: any) {
  //   return this.http.post<any>(`${environment.api}/update-pcr`, data);
  // }

  downloadPMGSlide(){
    return this.http.get<any>(`http://localhost:9002/api/PMGslide`);
    
  }

  getyearData(data: any) {
    return this.http.post<any>(`${environment.api}/getyearData`, data);
  }

  getPMOProjectCompletion(data: any) {
    return this.http.post<any>(`${environment.api}/getPMOProjectCompletion`, data);
  }

  getPMGProjects(data: any) {
    return this.http.post<any>(`${environment.api}/get-pmg-projects`, data);
  }

  updateProjectsortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-sortid`,
      data
    );
  }

  saveProjectPMGUrl(data: any) {
    return this.http.post<any>(`${environment.api}/save-Project-PMG-Url`, data);
  }

  getPMGProjectID(data: any) {
    return this.http.post<any>(`${environment.api}/get-PMG-Project-id`, data);
  }

  updateProjectMilestonesortId(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-project-milestone-sortid`,
      data
    );
  }

  approveTorBehalfSponser(data: any) {
    return this.http.post<any>(
      `${environment.api}/approveTorBehalfSponser`,
      data
    );
  }

}
