import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
@Injectable({
  providedIn: 'root'})
export class EventService {

  empAdded=new BehaviorSubject(null);
  notifyCount=new BehaviorSubject(null);
  projectDetails=new BehaviorSubject(null);
  bookmark=new BehaviorSubject(null);
  openActivity=new BehaviorSubject(null);
  notifyTeamNotification=new BehaviorSubject(null);
  tabSelection = new BehaviorSubject(null);
  tab1Selection = new BehaviorSubject(null);
  tab2Selection = new BehaviorSubject(null);
  tab3Selection = new BehaviorSubject(null);
  
  check = new BehaviorSubject(null);
  progressAdded = new BehaviorSubject(null);
  notificationAdded = new BehaviorSubject(null);
  activityAdded = new BehaviorSubject(null);
  activityAdded2=new BehaviorSubject(null);
  percentage = new BehaviorSubject(null);
  efforts = new BehaviorSubject(null);
  templateAdded = new BehaviorSubject(null);
  employeeProfile = new BehaviorSubject(null);
  milestonetasklistUpdated = new BehaviorSubject(null);
  companyUpdated = new BehaviorSubject(null);
  projectUpdated = new BehaviorSubject(null);
  activityUpdated = new BehaviorSubject(null);
  activityDeleted = new BehaviorSubject(null);
  statusAdded = new BehaviorSubject(null);
  revisedDateUpdated = new BehaviorSubject(null);
  cardUpdate = new BehaviorSubject(null);
  projectViewChange = new BehaviorSubject(null);
  tabMenuUpdate = new BehaviorSubject(null);
  logout = new BehaviorSubject(null);
  toaster = new BehaviorSubject(null);
  closePopup = new BehaviorSubject(null);
  profileUpdate = new BehaviorSubject(null);
  documentCount = new BehaviorSubject(null);
  editTemplate = new BehaviorSubject(null);
  notificationCount = new BehaviorSubject(null);
  closed = new BehaviorSubject(null);


  constructor() {

    
  }

 
}
