import { LoginCheckComponent } from './modules/auth/pages/login-check/login-check.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './modules/Auth/pages/forgot-password/forgot-password.component';
import { LoginComponent } from './modules/Auth/pages/login/login.component';
import { ExportPMGReportComponent } from './modules/auth/pages/export-pmg-report/export-pmg-report.component';
import { ExportMosReportComponent } from './modules/auth/pages/export-mos-report/export-mos-report.component';

const routes: Routes = [ 
  { path: '', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'export-pmg/:id', component: ExportPMGReportComponent },
  { path: 'checkLogin/:email', component: LoginCheckComponent },
  { path: 'export-mos/:id', component: ExportMosReportComponent },
  

  {
    path: 'home',
    loadChildren: () =>
      import('./modules/home/pages/layout.module').then((m) => m.LayoutModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
