import { StickyService } from 'src/app/core/services/sticky.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { WebService } from 'src/app/core/http/web-service.service';
import { PwaService } from 'src/app/core/services/PwaService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  private formSumitAttempt: boolean;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  version: string = "";
  public loading: boolean;
  public redirecting: boolean;
  public showPasswordFlag: boolean = false;
  subscription: any;

  constructor(private _router: Router, public swUpdate: PwaService, private _formBuilder: FormBuilder,
    private _webService: AuthService, public sticky: StickyService, private webService: WebService, private spinner: NgxSpinnerService) {
    this.version = environment.version;

    // const event = new KeyboardEvent('keydown', {
    //   key: 'R',
    //   code: 'KeyR',
    //   ctrlKey: true,
    //   shiftKey: true,
    //   bubbles: true,
    //   cancelable: true,
    // });
    // window.dispatchEvent(event);

     this.subscription = _router.events.subscribe((val) => {
      // console.log("============================", val instanceof NavigationEnd);
      if (val instanceof NavigationEnd) {
        console.log("route changes=========", window.location.href.substring(window.location.href.indexOf('id_token=') + 9, window.location.href.indexOf('&state=')))
        // console.log("window.location.href.includes============================", window.location.href.includes('checkLogin='));
        // console.log("window.location.href.includes============================", window.location.href.substring(window.location.href.indexOf('checkLogin=') + 11));
        // localStorage.clear();
        // // }
        // this._webService.token = null;
        // this._webService.name = null;
        // this._webService.role = null;
        // this._webService.id = null;
        if (window.location.href.includes('checkLogin=')) {

        //  this.checkLogin(window.location.href.substring(window.location.href.indexOf('checkLogin=') + 11));
        } else {
           console.log("this._webService.getUserFromSorage()============================", !this._webService.getUserFromSorage());

          if (!this._webService.getUserFromSorage()) {
            localStorage.clear();
            this._webService.token = null;
            this._webService.name = null;
            this._webService.role = null;
            this._webService.id = null;
            const cache = window.caches;
            cache.keys().then(keys => {
              for (const key of keys) {
                cache.delete(key);
              }
            });

          // window.open('https://drishti.canarahsbclife.com/api/saml/login', '_self')
          } else {
            if(this._webService.getUserFromSorage()){

              let response = this._webService.getUserFromSorage();
              this._webService.token = response.token;
              this._webService.name = response.name;
              this._webService.role = response.role;
              this._webService.id = response.id;
              this._webService.code = response.code;
              this._webService.project_approvar = response.project_approvar;
              this._webService.appAccess = response.appAccess;
              this.webService.appAccessSO = response.appAccessSO;
              
              this._router.navigate(['home']);
            }
          }
        }

      }  
    })
    // restApiService - START
    swUpdate.checkUserAgent();
    swUpdate.trackStandalone();
    window.addEventListener('beforeinstallprompt', (e) => {

      // show the add button
      swUpdate.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      swUpdate.deferredPrompt = e;
      swUpdate.promptSaved = true;

    });
    window.addEventListener('appinstalled', (evt) => {
      swUpdate.trackInstalled();
      // hide the add button
      // swUpdate.promptIntercepted = false;
    });
    // swUpdate - END
  }

  ngOnInit() {

    this.sticky.setValue(false)
    this.loginForm = this._formBuilder.group({
      userId: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(18)]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
  loginAction() {
    this.formSumitAttempt = true;
    if (this.loginForm.valid) {
      this.isWebRequest = true;
      this.redirecting = false;
      this.loading = true;
      let user = {
        userId: this.loginForm.value.userId,
        password: this.loginForm.value.password,
      }
      this.spinner.show();
      this._webService.loginUser(user).subscribe(
        (response: any) => {

          this._webService.token = response.token;
          this._webService.name = response.name;
          this._webService.role = response.role;
          this._webService.id = response.id;
          this._webService.code = response.code;
          this._webService.project_approvar = response.project_approvar;
          this._webService.appAccess = response.AppAccess;
          this.webService.appAccess = response.AppAccess;
          this._webService.saveUserToSorage({
            'token': response.token,
            'name': response.name,
            'empname': response.empname,
            'code': response.code,
            'role': response.role,
            'project_approvar': response.project_approvar.toString(),
            'id': response.id,
            'appAccess': JSON.stringify(response.AppAccess),
            'appAccessSO': JSON.stringify(response.AppAccessSO)
          })
          this.webService.saveUserinfoToSorage({
            'empname': response.empname,
            'version': response.version,
            'code': response.code,
            'project_approvar': response.project_approvar.toString(),
            'id': response.id,
          })
          this.loading = false;
          this.isWebRequest = false;
          this.loading = false;
          this.redirecting = true;
          this.spinner.hide();
          if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'Y') {

            this._router.navigate(['home']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/projects-list']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/time-log']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/report-view']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/settings']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'Y'
          ) {
            this._webService.showalert("Forum comming soon", 'success')
            // this._router.navigate(['/home/not-access-tool']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "HELP").access_flag == 'Y'
          ) {
            // this._webService.showalert("Forum comming soon",'success')
            this._router.navigate(['/home/help-menu']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'Y'
          ) {
            // this._webService.showalert("Forum comming soon",'success')
            this._router.navigate(['/home/view-employee/', this._webService.id]);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'N'
            || this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'N'
          ) {
            this._router.navigate(['/home/unautherized']);

          }



        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();


          if (err.status === 400) {
            this._webService.showalert(err.error.message, "error");

          } else {
            this._webService.showalert('Server error', "error");
          }
          this.isWebRequest = false;
          setTimeout(() => { this.responseMsg = '' }, 3000)
          this.loading = false;
        }
      );
    }
  }

  Download() {
    window.open(environment.assets + "/downloads/user_manual.pdf");
  }
  checkLogin(token) {
    let data = {
      token: token,
    }
    this.spinner.show();
    this.webService.loginUser(data).subscribe((response: any) => {
      this._webService.token = response.token;
      this._webService.name = response.name;
      this._webService.role = response.role;
      this._webService.id = response.id;
      this._webService.code = response.code;
      this._webService.project_approvar = response.project_approvar;
      this._webService.appAccess = response.AppAccess;
      this.webService.appAccess = response.AppAccess;
      this._webService.saveUserToSorage({
        'token': response.token,
        'name': response.name,
        'empname': response.empname,
        'code': response.code,
        'role': response.role,
        'project_approvar': response.project_approvar.toString(),
        'id': response.id,
        'appAccess': JSON.stringify(response.AppAccess),
        'appAccessSO': JSON.stringify(response.AppAccessSO)
      })
      this.webService.saveUserinfoToSorage({
        'empname': response.empname,
        'version': response.version,
        'code': response.code,
        'project_approvar': response.project_approvar.toString(),
        'id': response.id,
      })
      this.loading = false;
      this.isWebRequest = false;
      this.loading = false;
      this.redirecting = true;
      this.spinner.hide();
      if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'Y') {

        this._router.navigate(['home']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/projects-list']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/time-log']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/report-view']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/settings']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'Y'
      ) {
        this._webService.showalert("Forum comming soon", 'success')
        // this._router.navigate(['/home/not-access-tool']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "HELP").access_flag == 'Y'
      ) {
        // this._webService.showalert("Forum comming soon",'success')
        this._router.navigate(['/home/help-menu']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'Y'
      ) {
        // this._webService.showalert("Forum comming soon",'success')
        this._router.navigate(['/home/view-employee/', this._webService.id]);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'N'
        || this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'N'
      ) {
        this._router.navigate(['/home/unautherized']);

      }
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();

        if (err.status === 400) {
          this._webService.showalert(err.error.message, 'error');;
        } else {
          this._webService.showalert('Server error', 'error');;
        }
      });
  }
}
