import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { WebService } from '../http/web-service.service';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        public _webService: WebService,
        private _router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let roles = route.data["data"] as Array<string>;
        console.log(roles);
        if (roles == undefined || !roles) {
            // console.log(this._router.getCurrentNavigation().extras.state['data']);
            roles = this._router.getCurrentNavigation().extras.state['data'];
        }
        if (!this._webService.checkAccess(roles[0])) {
            this._router.navigate(['/home/unautherized']);
            return false
        }
        return true;

    }

}