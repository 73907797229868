
<div class="container-fluid">
    <!-- <img src="./assets/icons/Login/forgot-password-bg-1.jpg" class="bg-img" /> -->
    <div class="text-start">
        <h5 class="mt-1 font-weight-bold mb-0 text-start">
        </h5>
    </div>
</div>

<div class="row">

    <div class="col-12" style="float: left;margin-left: 1%;">
        <button class="btn btn-1 btn-sm pb-0 py-0" type="button" (click)="
        copyText()" >
             Copy Link 
          </button>   
           &nbsp;
          <button class="btn btn-1 btn-sm pb-0 py-0" type="button" (click)="exportDatas('id2', 'img', 'MOS_Export')">
            Download MOS
          </button>            
    </div>

</div>

<div class="modal-body" >
    <div *ngIf="allProjectSlides.length>0">
        <!-- <div class="child"></div> -->

        <div id="id2" class="parent"  >
                <div *ngFor="let item of allProjectSlides" >
        
        
        
                        <div class="row">
                            <div class="col-2">
                                <img class="pb-3" src="./assets/icons/can_logos.jpg" alt="" style="float: left;">
                            </div>
        
                            <div class="col-10 text-center" style="margin-top: 2%;">
                                <h5 class="text-center mb-3 font-weight-bold" style="transform: translateX(-5%);">
                                    {{ item.project_name }}
                                </h5>
                            </div>
        
                        </div>
        
        
                        <table class="table table-bordered table-sm table-hover">
                            <thead class="header-bg1">
                                <tr> 
                                    <th style="width: 2%">Sr.No.</th>
                                    <th style="width: 30%"> MoS Type
                                    </th>
                                    <th style="width: 15%"> Applicability to the Project
                                    </th>
                                    <th style="width: 15%"> Current State
                                    </th>
                                    <th style="width: 15%"> Envisaged
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="body-bg1">
                    
                                <tr *ngFor="let items of item.mosArray; let i = index">
                                    <td>{{i + 1}}</td>
                                    <td>{{items.name}}</td>
                                    <td>{{items.appliicabiity_of_project}}</td>
                                    <td>{{items.current_state}}</td>
                                    <td>{{items.envisaged}}</td>
                                </tr>
                    
                           </tbody>
                        </table>

                        <hr style="border-top: dotted 3px;" />
                        <br>
        
                </div>


        </div>        
    </div>
</div>