import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ToastrManager } from "ng6-toastr-notifications";
import { Subscription } from "rxjs/internal/Subscription";
import { DatePipe } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  invokeFirstComponentFunction = new EventEmitter();
  subsVar: Subscription;

  token: string;
  name: string;
  role: string;
  code: string;
  id: string; project_approvar: string;
  appAccess: any[];
  profilePic: any;
  companyLogo: any;
  perPage = 10;
  templateId: any;
  templatearray: any;
  item: any;
  isDesc: boolean = false;
  dashboard_type: string = null;
  optionType: string = null;
  new_type: string = null;
  viewFlag: boolean = false;
  constructor(private http: HttpClient, private datePipe: DatePipe,
    public toastr: ToastrManager,
    private storageService: StorageService
  ) {
    let user = this.getUserFromSorage();

    if (user) {
      this.token = user.token;
      this.name = user.name;
      this.role = user.role;
      this.id = user.id;
      this.code = user.code;
      //this.appAccess = JSON.parse(user.appAccess);
    }
    if (this.getCompanyLogosFromSorage()) {
      this.companyLogo = this.getCompanyLogosFromSorage();
    } else {
      this.companyLogo = "assets/images/logo.png";
    }
    if (this.getLogosFromSorage()) {
      this.profilePic = this.getLogosFromSorage();
    } else {
      this.profilePic = "assets/images/user.jpg";
    }
  }

  onFirstComponentButtonClick(name: any) {
    this.invokeFirstComponentFunction.emit(name);
  }

  dateConverter(dateString) {
    if (dateString) {
      var date = new Date(dateString);
      // //
      return this.datePipe.transform(date, "yyyy-MM-dd")
    } else {
      return ""
    }

  }
  getLogosFromSorage() {
    return this.storageService.secureStorage.getItem("profilelogo");
  }
  savelogosToSorage(user: any) {
    if (this.loggedIn())
      this.storageService.secureStorage.setItem("profilelogo", user);
  }
  getCompanyLogosFromSorage() {
    return this.storageService.secureStorage.getItem("logo");
  }
  saveCompanylogosToSorage(user: any) {
    this.storageService.secureStorage.setItem("logo", user);
  }

  saveUserToSorage(user: any) {
    this.storageService.secureStorage.setItem("user", user);
  }

  getUserFromSorage() {
    return this.storageService.secureStorage.getItem("user");
  }

  convertBase64ToBlobData(
    base64Data: string,
    contentType: string = "image/png",
    sliceSize = 512
  ) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  attachmentDownload(base64content: string, contentType: string, name: string) {
    const blobData = this.convertBase64ToBlobData(base64content, contentType);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //IE
      window.navigator.msSaveOrOpenBlob(blobData, name);
    } else {
      // chrome
      const blob = new Blob([blobData], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement("a");
      link.href = url;
      link.download = name;
      link.click();
    }
  }
  showalert(message: string, type: string) {

    switch (type) {
      case 'success':
        this.toastr.successToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;
      case 'info':
        this.toastr.infoToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;

      default:
        this.toastr.errorToastr(message, "", {
          position: "top-full-width",
          animate: "slideFromTop",
          toastTimeout: 3000,
          showCloseButton: true

        });
        break;
    }
  }
  showFirebaseNotification(title: string, message: string) {
    this.toastr.infoToastr(title, message, {
      position: "bottom-right",
      animate: "slideFromBottom",
      toastTimeout: 7000,
    });
  }

  loginUser(data: any) {
    return this.http.post<any>(`${environment.api}/login`, data);
  }

  sendResetPasswordLink(data: any) {
    return this.http.post<any>(
      `${environment.api}/send-reset-password-link`,
      data
    );
  }

  setNewPassword(data: any) {
    return this.http.post<any>(`${environment.api}/set-new-password`, data);
  }

  loggedIn() {
    return this.token != null;
  }

  getToken() {
    return this.token;
  }

  removeToken() {
    this.token = null;
  }

  updateNotificationSeen(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-notification-seen`,
      data
    );
  }
  updateNotificationSeenAll(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-notification-seen-all`,
      data
    );
  }
  markAsSeen(data: any) {
    return this.http.post<any>(`${environment.api}/mark-post-as-seen`, data);
  }
  updateFirebaseToken(data: any) {
    return this.http.post<any>(
      `${environment.api}/update-firebase-token`,
      data
    );
  }
  getCountAndNotifications(data: any) {
    return this.http.post<any>(
      `${environment.api}/get-count-and-notifications`,
      data
    );
  }
}
