
<div class="container-fluid">
    <!-- <img src="./assets/icons/Login/forgot-password-bg-1.jpg" class="bg-img" /> -->
    <div class="text-start">
        <h5 class="mt-1 font-weight-bold mb-0 text-start">
        </h5>
    </div>
</div>

<div class="row">

    <div class="col-12" style="float: left;margin-left: 3%;">
        <button class="btn btn-1 btn-sm pb-0 py-0" type="button" (click)="
        copyText()" >
             Copy Link 
          </button>           
          &nbsp;
          <button class="btn btn-1 btn-sm pb-0 py-0" type="button" (click)="exportDatas('id1', 'img-new', 'PMG_Export')">
            Download PMG
          </button>
    </div>

</div>

<div class="modal-body" >
    <div *ngIf="allProjectSlides.length>0">
        <!-- <div class="child"></div> -->
        <div id="id1" class="parent"  >

            <div *ngFor="let item of allProjectSlides" id="pagebreak" class="html2pdf__page-break">

                <div class="container-fluid">
                    <div class="col-12">




                        <div class="row">
                            <div class="col-2">
                                <img class="pb-3" src="./assets/icons/can_logos.jpg" alt="" style="float: left;">
                            </div>

                            <div class="col-10 text-center" style="margin-top: 2%;">
                                <h5 class="text-center mb-3 font-weight-bold" style="transform: translateX(-5%);">
                                    {{ item.project_name }}
                                </h5>
                            </div>

                        </div>
                        <div  *ngIf="item.project_objective" class="row" style="width: 100%;margin-left:0% ;padding-bottom: 0.5%;">

                            <div class="card flex-fill" style="font-size: 14px;font-weight: 600;margin-top: 1%;">
                                <div class="card-body body-bg p-1" [innerHTML]="item.project_objective">
                                    <!-- <span [innerHTML]="keyUpdates"></span> -->
                                    
                                </div>
                            </div>
                
                        </div>

                        <div class="row">
                            <div class="col-4 d-flex flex-fill">
                                <div class="card flex-fill">
                                    <div class="header-bg card-header text-center p-2">Key Update</div>
                                    <div class="card-body body-bg p-2" [innerHTML]="item.key_updates">
                                    </div>
                                </div>
                            </div>
                            <div class="col-8">
                                <div>
                                    <table class="table table-sm text-center table-hover table-bordered body-bg">
                                        <tr>

                                            <td class="th1 text-left" [ngStyle]="{
                                        'background-color' : item.status_color
                                    }"> <strong>Project Status:</strong> {{ item.status }}</td>

                                            <td class="text-left">
                                                <strong>Sponsor : </strong> {{ item.sponserName }}
                                            </td>
                                            <td class="text-left">
                                                <strong>Project Manager :</strong> {{ item.managerName }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="text-left">
                                                <strong>Business Lead :</strong> {{ item.businessName }}
                                            </td>
                                            <td class="text-left">
                                                <strong>Technical Lead :</strong> {{ item.techLead }}
                                            </td>
                                            <td class="text-left">
                                                <strong>Process Expert :</strong> {{ item.expertName }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-left">
                                                <strong>Start Date :</strong> <span [innerHTML]="item.project_planned_start_date"></span>
                                            </td>

                                            <td class="text-left">
                                                <strong>Est. End :</strong> <span [innerHTML]="item.project_planned_end_date"></span>
                                            </td>
                                            <td class="text-left"><strong>SME :</strong> <span [innerHTML]="item.empNames"></span></td>
                                        </tr>
                                        <!-- <tr>
                                            <td colspan="3" class="text-left">
                                                <strong>Governance Strategy Configuration :</strong> <span [innerHTML]="item.gsc_header_name"></span>
                                            </td>
                                        </tr> -->
                                    </table>
                                </div>
                                <div>
                                    <table class="table table-sm text-center table-hover table-bordered">
                                        <thead class="header-bg1">
                                            <th class="text-left" style="width: 25%">Milestone Name</th>
                                            <th class="text-left">Activity</th>

                                            <th class="text-left">Responsibility</th>
                                            <th class="text-left " style="width: 12%">Previous End Date</th>
                                            <th class="text-left " style="width: 12%">End/Revised End Date</th>


                                            <th class="text-left">Status</th>
                                        </thead>
                                        <tbody class="body-bg1">
                                            <tr *ngFor="let task of item.activitiesArray; let i = index">
                                                <td class="th1 text-left">{{ task.milestone_name }}</td>
                                                <td class="th1 text-left">{{ task.activity_title }}</td>


                                                <td class="th1 text-left">{{ task.employee_name }}</td>
                                                <td class="th1 text-left">
                                                    {{ task.revised_date | date: "dd MMM yyyy" }}
                                                </td>
                                                <td class="th1 text-left">
                                                    {{ task.planned_end_date | date: "dd MMM yyyy" }}
                                                </td>
 
                                                <td class="th1 text-left" [ngStyle]="{
                                            'background-color' : task?.status_color
                                          }">{{ task.status }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <div style="display: inline-block; position: relative" *ngFor="let item1 of item.milestonesArray">
                                    <span title="{{item1.milestone_name}}" class="text-arrow text-center" [ngStyle]="{
                    color: item1.milestone_color == '#FFFF00' ? '#000000' : '#FFFFFF'
                  }">
                                        <!-- <span class="cut-text">{{ item.milestone_name  }}</span> -->
                                    </span>
                                    <div id="zz" [ngClass]="{
                    'arrow-left-grey': item1.milestone_color == '#808080',
                    'arrow-left-red': item1.milestone_color == '#FF0000',
                    'arrow-left-yellow': item1.milestone_color == '#FFFF00',
                    'arrow-left-green': item1.milestone_color == '#008000'
                  }"></div>
                                    <div id="zz" [ngClass]="{
                    'arrow-ctr-grey1': item1.milestone_color == '#808080',
                    'arrow-ctr-red1': item1.milestone_color == '#FF0000',
                    'arrow-ctr-yellow1': item1.milestone_color == '#FFFF00',
                    'arrow-ctr-green1': item1.milestone_color == '#008000'
                  }">
                                        {{ item1.milestone_name }}
                                    </div>
                                    <div id="zz" [ngClass]="{
                    'arrow-right-grey': item1.milestone_color == '#808080',
                    'arrow-right-red': item1.milestone_color == '#FF0000',
                    'arrow-right-yellow': item1.milestone_color == '#FFFF00',
                    'arrow-right-green': item1.milestone_color == '#008000'
                  }"></div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12">
                                <table class="table table-sm text-center table-hover table-bordered">
                                    <thead class="header-bg2">
                                        <th style="width: 45%">Risks and Mitigation actions</th>
                                        <th>Cost budgeted</th>
                                        <th>Cost incurred till date</th>
                                    </thead>
                                    <tbody class="body-bg2">
                                        <tr>
                                            <td style="text-align: justify">
                                                <span [innerHTML]="item.risk_remark"></span>
                                            </td>
                                            <td style="text-align: justify">
                                                <span [innerHTML]="item.dependancy_remark"></span>
                                            </td>
                                            <td style="text-align: justify">
                                                <span [innerHTML]="item.accomplishment_remark"></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border-top: dotted 3px;" />
                <br>
            </div>
        </div>

    </div>
</div>