import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { WebService } from 'src/app/core/http/web-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StorageService } from 'src/app/core/services/storage.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-export-pmg-report',
  templateUrl: './export-pmg-report.component.html',
  styleUrls: ['./export-pmg-report.component.css']
})
export class ExportPMGReportComponent implements OnInit {

  loginForm: FormGroup;
  private formSumitAttempt: boolean;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  version: string = "";
  public loading: boolean;
  public redirecting: boolean;
  projectId : any;
  sub: any;
  arr=[];
  allProjectSlides = [];
  url: any;
  auto_text: any;
  constructor(private _router: Router, private route: ActivatedRoute, private clipboard: Clipboard,
    private spinner: NgxSpinnerService,private StorageService1 :StorageService,public _sharedService: SharedService,
    private _formBuilder: FormBuilder, public _webService1: WebService,private _webService: AuthService,private location: Location) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      userId: [null, [Validators.required]]
    });
    console.log('this.route ',this.route)
    // var a = this.StorageService1.secureStorage.encrypt(this.projectArray.toString());
    this.sub = this.route.params.subscribe(params => {
      console.log('params =============== ',params.id)
      this.auto_text=params.id
      // console.log('this.StorageService1.secureStorage.encrypt(this.projectArray.toString()); ',this.StorageService1.secureStorage.decrypt(params.id))
      // var b = params.id.replaceAll('QWE', "/") 
      // console.log('b =============== ',b)
      // this.projectId = this.StorageService1.secureStorage.decrypt(b); // (+) converts string 'id' to a number
      
   });
    this.getPMGProjectID();

   // console.log('============== ',this.arr );
  }

  exportDatas(id: string,type: string,data:any) {
    this._sharedService.exportData(id,type,data);                     //<<<---using ()=> syntax
   }

  getPMGProjectID() {


    let data = {
      auto_text: this.auto_text,
    };
    this.spinner.show();
    this._webService1.getPMGProjectID(data).subscribe(
      (response: any) => {
        this.spinner.hide();
        console.log('response ///////////////',JSON.parse(response) )

        this.allProjectSlides = JSON.parse(response)
        console.log('this.allProjectSlides ',this.allProjectSlides)
        // this.arr = JSON.parse("[" + response + "]");
        // this.getAllProjectsliderDetails()
        this.allProjectSlides.forEach(element => {
          if(element.employees.length > 0)
          {
            let emps=[];
            var newEmpdata=[];
            element.employees.forEach(element => {
              if(!newEmpdata.find(obj=>obj==element.employee_name)){

                newEmpdata.push(element.employee_name);
              }
            });
          
            element.empNames=newEmpdata.toString();
          }

          if(element.activitiesArray.length > 0)
          {
            element.activitiesArray.forEach(element => {
              if(element.previous_revised_end_date)
              {
                element.revised_date=element.previous_revised_end_date
              }
              if(element.previous_revised_end_date1)
              {
                element.revised_date=element.previous_revised_end_date1
              }
              if(element.previous_revised_end_date2)
              {
                element.revised_date=element.previous_revised_end_date2
              }
              if(element.previous_revised_end_date3)
              {
                element.revised_date=element.previous_revised_end_date3
              }
              if(element.previous_revised_end_date4)
              {
                element.revised_date=element.previous_revised_end_date4
              }
            });
          }
          
        });

      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();

        if (err.status === 400) {
          this.responseMsg = err.error.message;
        } else {
          this.responseMsg = 'Server error';
        }
      }
    );
  }


  get f() {
    return this.loginForm.controls;
  }

  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSumitAttempt)
    );
  }


  getAllProjectsliderDetails() {


    let data = {
      projectId: this.arr,
    };
    this.spinner.show();
    this._webService1.getAllProjectsliderDetails(data).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.allProjectSlides = [];
        this.allProjectSlides = response.slider
        this.allProjectSlides.forEach(element => {
          if (element.project_objective) {
            element.project_objective = "Project Objective : " + element.project_objective
          }
        });
        console.log("allProjectSlides=====", this.allProjectSlides)


      },
      (err: HttpErrorResponse) => {
        this.spinner.hide();

        if (err.status === 400) {
          this.responseMsg = err.error.message;
        } else {
          this.responseMsg = 'Server error';
        }
      }
    );
  }

  copyText() {

    let exportUrl = window.location.origin + window.location.pathname + '#/export-pmg/' + this.auto_text
    this.clipboard.copy(exportUrl);
    this._webService.showalert('Text copied to clipboard', 'success');
  }

  goBack()
  {

  }
}

