import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';



@Component({
	selector: 'app-nondismissable',
	templateUrl: './nondismissable.component.html',
	styleUrls: ['./nondismissable.component.scss']
})
export class NonDismissableComponent implements OnInit {
	@Input() heading: string = "Confirm";
	@Input() id: any;
	@Input() message: string = "Are you sure ? You want to delete.";
	@Output() notifyParent: EventEmitter<any> = new EventEmitter();
	@Input() schedule: string;
	@Input() rejectProject: boolean;
	showSchedule: boolean = false;
	isChecked: any;

	remark: any;
	onClose: Subject<boolean>;
	constructor(public activeModal: BsModalService, public bsModalRef1: BsModalRef) {
	}

	ngOnInit() {


		if (this.schedule) {
			this.showSchedule = true;
		}
	}
	checkValue(event: any) {
		this.isChecked = event;

	}
	confirm() {
		this.activeModal.hide(this.id);
		if (this.schedule) {
			var item = { result: true, isChecked: this.isChecked, remark: "" }
			this.notifyParent.emit(item);
		} else if (this.rejectProject) {
			var item2 = { result: true, isChecked: false, remark: this.remark }
			this.notifyParent.emit(item2);
		} else {
			var item1 = { result: true, isChecked: false, remark: "" }
			this.notifyParent.emit(item1);
		}

	}

	close() {
		this.notifyParent.emit(false);
		this.activeModal.hide(this.id);
	}
}
