
<div class="container-fluid">
    <!-- <img src="./assets/icons/Login/forgot-password-bg-1.jpg" class="bg-img" /> -->
    <div class="text-start">
        <h5 class="mt-1 font-weight-bold mb-0 text-start">
            <a href="javascript:void(0)" (click)="goBack()">
                <img src="../../../../../assets/icons/back-1.png" width="25" tooltip="" class="mr-1 mb-1" />
            </a>
        </h5>
    </div>
</div>

<div class="container mt-5">
    <div class="row justify-content-center text-center">
        <div class="col-5 mt-5">
            <div>
                <img src="./assets/icons/Login/forgot-password.png" style="max-width: 200px" />
            </div>
            <form [formGroup]="loginForm" autocomplete="off">
                <h3 class="font-weight-bold custom-form-group">Forgot Your Password?</h3>
                <p class="text-muted custom-form-group">
                    To recover your password, You need enter your registered mobile address. We will send the recovery code to you email.
                </p>
                <div class="mx-5">
                    <div class="mb-4">
                        <div class="position-relative">
                            <img class="position-absolute border-right pr-2 icon-position" src="./assets/icons/Login/message.png" width="24" /><input [ngClass]="{'parsley-error': isFieldValid('userId')}" formControlName="userId" class="form-control badge-pill shadow custom-input"
                                placeholder="Enter your email" type="text" />
                            <div class="parsley-errors-list filled pt-1" *ngIf="isFieldValid('userId')">
                                <p class="parsley-required text-left" style="padding-left: 15px;" *ngIf="f.userId.errors.required">Email Id is required.</p>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group">
                        <button class="btn btn-3 form-control badge-pill shadow-none" (click)="loginAction()" [disabled]="isWebRequest">
                            Send
                        </button>
                 
                        <div *ngIf="redirecting" style="margin-top: 10px;" class="text-center">Redirecting Please wait... <i class="fa fa-spinner fa-spin"></i></div>
                    </div>
                    <div class="custom-form-group text-center">
                        <button class="btn btn-link" [routerLink]="['/']">
                            Go To Login
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>