import { EventService } from 'src/app/core/services/events.service';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { StickyService } from './core/services/sticky.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'my-app';
  user: any;
  show: boolean=false;
  show1: any=false;
  public constructor(private titleService: Title,public sticky:StickyService,
    private swUpdate: SwUpdate,
    private authService:AuthService,public eventService:EventService) {
    this.setTitle();
    this.show=false;

    this.user = this.authService.getUserFromSorage();

  }
 
  public setTitle() {
    this.titleService.setTitle("Drishti ( " + environment.type + environment.version + " )");
  }


  ngOnInit(): void {
    this.sticky.getValue().subscribe(data=>{
      this.show = data
    });
    this.swUpdate.available.subscribe(event => {
      // this._webService.showalert("New update available", "info");
      this.swUpdate.activateUpdate().then(() => document.location.reload());

    });
    
      this.user = this.authService.getUserFromSorage();

      if(this.user&&this.user.id != null){
        this.show = true
      }else{
        this.show = false
      }

      // this.eventService.getData().subscribe((res:any)=>{
      //   console.log(res);
        
      // })

       
  

     

}


Shide(event){
      console.log("dfsfsfds",event);
      
}
}
