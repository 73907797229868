import { StickyService } from 'src/app/core/services/sticky.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { WebService } from 'src/app/core/http/web-service.service';
import { PwaService } from 'src/app/core/services/PwaService';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

const SECRET_KEY = 'n3vZjSxe6kmwWFffgvT3eridN5gXm20p';
@Component({
  selector: 'app-login-check',
  templateUrl: './login-check.component.html',
  styleUrls: ['./login-check.component.css']
})
export class LoginCheckComponent implements OnInit {

  loginForm: FormGroup;
  private formSumitAttempt: boolean;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  version: string = "";
  public loading: boolean;
  public redirecting: boolean;
  public showPasswordFlag: boolean = false;

  constructor(private _router: Router, public swUpdate: PwaService, private _formBuilder: FormBuilder,
    private _webService: AuthService,public sticky:StickyService, private webService: WebService,
    private route: ActivatedRoute,
     private spinner: NgxSpinnerService) {
    this.version = environment.version;
    
    let sub = this.route.params.subscribe((params) => {
      let email=CryptoJS.AES.decrypt(params['email'], SECRET_KEY).toString(CryptoJS.enc.Utf8);
      this.checkLogin(decodeURIComponent(email));
       
    });
  }

  ngOnInit() {
    
    this.sticky.setValue(false)
    this.loginForm = this._formBuilder.group({
      userId: [null, [Validators.required]],
      password: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(18)]]
    });
  }

   
  get f() {
    return this.loginForm.controls;
  }

  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  loginAction() {
    this.formSumitAttempt = true;
    if (this.loginForm.valid) {
      this.isWebRequest = true;
      this.redirecting = false;
      this.loading = true;
      let user = {
        userId: this.loginForm.value.userId,
        password: this.loginForm.value.password,
      }
      this.spinner.show();
      this._webService.loginUser(user).subscribe(
        (response: any) => {

          this._webService.token = response.token;
          this._webService.name = response.name;
          this._webService.role = response.role;
          this._webService.id = response.id;
          this._webService.code = response.code;
          this._webService.project_approvar = response.project_approvar;
          this._webService.appAccess = response.AppAccess;
          this.webService.appAccess = response.AppAccess;
          this._webService.saveUserToSorage({
            'token': response.token,
            'name': response.name,
            'empname': response.empname,
            'code': response.code,
            'role': response.role,
            'project_approvar': response.project_approvar.toString(),
            'id': response.id,
            'appAccess': JSON.stringify(response.AppAccess),
            'appAccessSO': JSON.stringify(response.AppAccessSO)
          })
          this.webService.saveUserinfoToSorage({
            'empname': response.empname,
            'version': response.version,
            'code': response.code,
            'project_approvar': response.project_approvar.toString(),
            'id': response.id,
          })
          this.loading = false;
          this.isWebRequest = false;
          this.loading = false;
          this.redirecting = true;
          this.spinner.hide();
          if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'Y') {

            this._router.navigate(['home']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/projects-list']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/time-log']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/report-view']);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'Y'
          ) {
            this._router.navigate(['/home/settings']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'Y'
          ) {
            this._webService.showalert("Forum comming soon", 'success')
            // this._router.navigate(['/home/not-access-tool']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "HELP").access_flag == 'Y'
          ) {
            // this._webService.showalert("Forum comming soon",'success')
            this._router.navigate(['/home/help-menu']);
          }
          else if (this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'Y'
          ) {
            // this._webService.showalert("Forum comming soon",'success')
            this._router.navigate(['/home/view-employee/', this._webService.id]);
          } else if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'N'
            || this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'N' ||
            this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'N'
          ) {
            this._router.navigate(['/home/unautherized']);

          }



        },
        (err: HttpErrorResponse) => {
          this.spinner.hide();


          if (err.status === 400) {
            this._webService.showalert(err.error.message, "error");

          } else {
            this._webService.showalert('Server error', "error");
          }
          this.isWebRequest = false;
          setTimeout(() => { this.responseMsg = '' }, 3000)
          this.loading = false;
        }
      );
    }
  }

  Download() {
    window.open(environment.assets + "/downloads/user_manual.pdf");
  }
  checkLogin(token) {
    let data = {
      token: token,
    }
    this.spinner.show();
    this.webService.loginAd(data).subscribe((response: any) => {
      this._webService.token = response.token;
      this._webService.name = response.name;
      this._webService.role = response.role;
      this._webService.id = response.id;
      this._webService.code = response.code;
      this._webService.project_approvar = response.project_approvar;
      this._webService.appAccess = response.AppAccess;
      this.webService.appAccess = response.AppAccess;
      this._webService.saveUserToSorage({
        'token': response.token,
        'name': response.name,
        'empname': response.empname,
        'code': response.code,
        'role': response.role,
        'project_approvar': response.project_approvar.toString(),
        'id': response.id,
        'appAccess': JSON.stringify(response.AppAccess),
        'appAccessSO': JSON.stringify(response.AppAccessSO)
      })
      this.webService.saveUserinfoToSorage({
        'empname': response.empname,
        'version': response.version,
        'code': response.code,
        'project_approvar': response.project_approvar.toString(),
        'id': response.id,
      })
      this.loading = false;
      this.isWebRequest = false;
      this.loading = false;
      this.redirecting = true;
      this.spinner.hide();
      if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'Y') {

        this._router.navigate(['home']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/projects-list']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/time-log']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/report-view']);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'Y'
      ) {
        this._router.navigate(['/home/settings']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'Y'
      ) {
        this._webService.showalert("Forum comming soon", 'success')
        // this._router.navigate(['/home/not-access-tool']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "HELP").access_flag == 'Y'
      ) {
        // this._webService.showalert("Forum comming soon",'success')
        this._router.navigate(['/home/help-menu']);
      }
      else if (this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'Y'
      ) {
        // this._webService.showalert("Forum comming soon",'success')
        this._router.navigate(['/home/view-employee/', this._webService.id]);
      } else if (this._webService.appAccess.find(obj => obj.menu_name == "DASHBOARD").access_flag == 'N'
        || this._webService.appAccess.find(obj => obj.menu_name == "PROJECTS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "TIMELOG").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "ANLYTICS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "SETTINGS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "FORUMS").access_flag == 'N' ||
        this._webService.appAccess.find(obj => obj.menu_name == "PROFILE").access_flag == 'N'
      ) {
        this._router.navigate(['/home/unautherized']);

      }
    },
      (err: HttpErrorResponse) => {
        this.spinner.hide();

        if (err.status === 400) {
          this._webService.showalert(err.error.message, 'error');;
        } else {
          this._webService.showalert('Server error', 'error');;
        }
      });
  }
}
