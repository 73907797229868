import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StickyService{
    valueObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    valueObs1: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() { }

  public setValue(value: any):void {
    this.valueObs.next(value);
    
    this.getValue();
    
    }

    public getValue():Observable<any> {
      return this.valueObs;
      }

      setTask(value:any):void{
        this.valueObs1.next(value);
    
        this.getTask();
        
      }
      public getTask():Observable<any>{
      return this.valueObs1;
        
      }
}