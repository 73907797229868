import { version } from '../../package.json';
import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: false,
  debug: true,
  api: 'https://canhsbc.autoflowtech360.com/api',
  assets: 'https://canhsbc.autoflowtech360.com/',
  logoutapi: "http://localhost:5004/api",
  profileUrl: 'https://canhsbc.autoflowtech360.com/uploads/Profile',
  documentUrl: 'https://canhsbc.autoflowtech360.com/uploads/document',
  maxFileSize: 6291456,
  version: version,
  public: 'https://canhsbc.autoflowtech360.com',
  type: 'LOCAL',
  firebase: {
    apiKey: 'AIzaSyAhb8Lsuf6P01mE9zmqrp1MZqFgPuCsfro',
    authDomain: 'autolync-d9505.firebaseapp.com',
    projectId: 'autolync-d9505',
    storageBucket: 'autolync-d9505.appspot.com',
    messagingSenderId: '697729106051',
    appId: '1:697729106051:web:fcf3a0179e5191f87a9bb6',
    measurementId: 'G-00B2HJ9X0T',
  },
  NgxLoggerLevel: NgxLoggerLevel.TRACE,
  NgxServerLogLevel: NgxLoggerLevel.ERROR,
};
