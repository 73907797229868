<div class="container-fluid">
    <img src="./assets/images/Login-Bg_5.jpg" class="bg-img" />
</div>
<div class="container mt-5">
    <div class="row">
        <div class="col-lg-8 text-center">
            <img src="../assets/images/Logo.png" style="width:400px ;">
        </div>
        <div class="col-4 mt-5 ">
            <form [formGroup]="loginForm" autocomplete="off" style="text-align: center;">
                <!-- <h2 class="colorh" style="font-family: Arial Rounded MT;
                font-weight: bolder;font-size: 4rem;margin: 0px;">Drishti</h2> -->
                <img src="../assets/icons/dashboard_logo.png" style="height: 100px; margin-top: -19px;margin-bottom: 18px;">
                <!-- <h2 class="colorS" style="font-family: Arial Rounded MT;
                font-weight: bolder;margin: 0px;margin-top: -12px;">Sign in</h2> -->
                <h4 class="colorS" style="font-family: Arial Rounded MT;
                font-weight: bolder;margin: 0px;">Welcome to <span class="colorW">Drishti</span></h4>


                <!-- 
                <h3 class="font-weight-bold">Sign In</h3>
                <h4>
                    <span class="font-weight-light">Welcome to </span><strong>Autolync</strong>
                </h4>
                <div class="my-3" style="border-bottom: 5px solid #fc9f00; width: 20px"></div> -->
                <div class="mb-4">
                    <div class="position-relative">
                        <img class="position-absolute border-right pr-2 icon-position" src="./assets/images/Username.png" width="24" /><input class="form-control badge-pill shadow custom-input" placeholder="Username" [ngClass]="{ 'parsley-error': isFieldValid('userId') }"
                            formControlName="userId" type="text" />
                        <div class="parsley-errors-list filled pt-1" *ngIf="isFieldValid('userId')">
                            <p class="parsley-required" style="padding-left: 15px;" *ngIf="f.userId.errors.required">
                                Username is required.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <div class="position-relative">
                        <img class="position-absolute border-right pr-2 icon-position" src="./assets/images/Password.png" width="24" /><input class="form-control badge-pill shadow custom-input" placeholder="Password" [ngClass]="{ 'parsley-error': isFieldValid('password') }"
                            formControlName="password" [type]="showPasswordFlag ? 'text' : 'password'" />
                        <i class="position-absolute cursor-pointer fa text-color" [ngClass]="{
                'fa-eye': !showPasswordFlag,
                'fa-eye-slash': showPasswordFlag
              }" style="right: 1rem; top: 1rem" src="./assets/icons/Login/password.png" (click)="showPasswordFlag = !showPasswordFlag"></i>
                        <div class="parsley-errors-list filled pt-1" *ngIf="isFieldValid('password')">
                            <p class="parsley-required" style="padding-left: 15px;" *ngIf="f.password.errors.required">
                                Password is required.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="custom-form-group">
                    <button class="btn btn-3 form-control badge-pill shadow-none" (click)="loginAction()" [disabled]="isWebRequest">
                        <span *ngIf="!redirecting">LOGIN </span>

                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                        <span *ngIf="redirecting">Please Wait.. </span>
                    </button>
                </div>
                <div class="custom-form-group text-center">
                    <!-- <button class="btn btn-link" [routerLink]="['/forgot-password']">
                        Forgot password?
                    </button> -->
                </div>
            </form>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-pulse">
</ngx-spinner>