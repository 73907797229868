import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm: FormGroup;
  private formSumitAttempt: boolean;
  responseMsg: string = "";
  isWebRequest: boolean = false;
  version: string = "";
  public loading: boolean;
  public redirecting: boolean;

  constructor(private _router: Router, private _formBuilder: FormBuilder, private _webService: AuthService,private location: Location) {
    this.version = environment.version;
  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      userId: [null, [Validators.required]]
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  isFieldValid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSumitAttempt)
    );
  }

  loginAction() {
    this.formSumitAttempt = true;
    if (this.loginForm.valid) {
      this.isWebRequest = true;
      this.redirecting = false;
      this.loading = true;
      let user = {
        userId: this.loginForm.value.userId
      }
      this._webService.sendResetPasswordLink(user).subscribe(
        (response: any) => {
          this._webService.showalert(response.message, "success");
          this.loading = false;

          this._router.navigate(['']);
          this.isWebRequest = false;
          this.loading = false;
          this.redirecting = true;
        },
        (err: HttpErrorResponse) => {
          if (err.status === 400) {
            this._webService.showalert(err.error.message, "error");

          } else {
            this._webService.showalert('Server error', "error");
          }
          this.isWebRequest = false;
          setTimeout(() => { this.responseMsg = '' }, 3000)
          this.loading = false;
        }
      );
    }
  }
  goBack() {
    // window.history.back();
    this.location.back();
  }

}

