import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx-js-style';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  elem: any;
  fullScreen: boolean = false;
  notifyParent: EventEmitter<any> = new EventEmitter();
  isGraph = true;
  constructor(
    public http: HttpClient,
    @Inject(DOCUMENT) private document: any,
    public spinner: NgxSpinnerService,
  ) {}



  openFullscreen(id: any) {
    this.fullScreen = true;
    this.elem = document.getElementById(id);
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  closeFullscreen(id: any) {
    this.fullScreen = false;

    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }

  async exportData(id: string,type:string,name: string) {
    const doc = document.getElementById(id) as HTMLTableElement;
    this.spinner.show();

    switch (type) {
      case 'img':
        html2canvas(doc, {
          onclone: (clonedDoc:  Document) => {
            clonedDoc.getElementById(id)!.classList.remove("d-none");
            clonedDoc.getElementById(id)!.style.display = 'block'
          }
        }).then((canvas) => {
          const link = document.createElement('a');
          link.href = canvas.toDataURL('image/jpeg',1);
          link.download = name + '.jpeg';
          document.body.appendChild(link);
          link.click();
          this.spinner.hide();
        })
        break;
        case 'img-new':
          html2canvas(doc, {
            onclone: (clonedDoc:  Document) => {
              clonedDoc.getElementById(id)!.classList.remove("d-none");
              clonedDoc.getElementById(id)!.style.display = 'block'
            }
          }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg',0.5);
            link.download = name + '.jpeg';
            document.body.appendChild(link);
            link.click();
            this.spinner.hide();
          })
          break;  
      case 'pdf':
        try {

          const [w, h] = [doc.offsetWidth, doc.offsetHeight];
          const pdf = new jsPDF('l', 'px', [w, h]);
          autoTable(pdf, {
            styles: {
              valign: 'middle',
              halign: 'center',
            },
            html: '#' + id,
            theme: 'grid',
            headStyles: {
              fillColor: [255, 255, 255],
              textColor: [221, 119, 51],
              lineWidth: 1,
            },
          });
          pdf.save(name + '.pdf');
    this.spinner.hide();

        } catch (e) {
          let [w, h] = [0,0];
          html2canvas(doc, {
           // scale: 5,
            // dpi: 144,
            onclone: (clonedDoc:  Document) => {
              // clonedDoc.getElementById(id)!.classList.remove("d-none");
              // clonedDoc.getElementById(id)!.style.display = 'block';
              [w, h] = [clonedDoc.getElementById(id)!.offsetWidth, clonedDoc.getElementById(id)!.offsetHeight]
              console.log(clonedDoc.getElementById(id)!.offsetWidth,clonedDoc.getElementById(id)!.offsetHeight);
            }
            
          }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('l', 'px', [w, h]);
            pdf.addImage(imgData, 'JPG', 0, 0, w, h);

            this.spinner.hide();
            pdf.save(name + '.pdf');
          })
        }
        break;

        case 'pdfs':
          try {
             const [w, h] = [595 , 874];

            const pdf = new jsPDF("l", "pt", "a4");
            autoTable(pdf, {
              styles: {
                valign: 'middle',
                halign: 'center',
              },
              html: '#' + id,
              theme: 'grid',
              headStyles: {
                fillColor: [255, 255, 255],
                textColor: [221, 119, 51],
                lineWidth: 1,
              },
            });
            pdf.save(name + '.pdf');
            this.spinner.hide();
          } catch (e) {
            let [w, h] = [0,0];
            html2canvas(doc, {
              onclone: (clonedDoc:  Document) => {
                clonedDoc.getElementById(id)!.classList.remove("d-none");
                clonedDoc.getElementById(id)!.style.display = 'block';
                [w, h] = [clonedDoc.getElementById(id)!.offsetWidth, clonedDoc.getElementById(id)!.offsetHeight]
              }
            }).then((canvas) => {
              const imgData = canvas.toDataURL('image/png');
              const pdf = new jsPDF('l', 'px', [w, h]);
              pdf.addImage(imgData, 'PNG', 0, 0, w, h);
              pdf.save(name + '.pdf');
              this.spinner.hide();
            })
          }
          break;
      case 'excel':
        try {
          // const ws = XLSX.utils.json_to_sheet(data);
          const ws = XLSX.utils.table_to_sheet(doc, { raw: true });
          console.log('ws ===================== ',ws)
          ws['!cols'] = [];

          // this.wrapAndCenterCell(ws.K2);
          var range = XLSX.utils.decode_range(ws['!ref']!);
          console.log('range ===================== ',range)
          for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
            ws['!cols'].push({ wpx: 330 })
          }
          var row;
          var rowNum;
          var colNum;
          for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            for (colNum = range.s.c; colNum <= range.e.c; colNum++) {

              
              var nextCell =
                ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];

              if (typeof nextCell !== 'undefined') {
                let val = nextCell.v.replace(',', '').replace('%', '');
                nextCell.s = {
                  font: {
                    sz: 14,
                    bold: rowNum == 0 ? true : false,
                  },
                  border: {
                    top: { style: 'thin', color: { rgb: '00000000' } },
                    bottom: { style: 'thin', color: { rgb: '00000000' } },
                    left: { style: 'thin', color: { rgb: '00000000' } },
                    right: { style: 'thin', color: { rgb: '00000000' } },
                  },
                  alignment: {
                    wrapText: true,
                    vertical: 'center',
                    horizontal: 'center',
                  },
                  // numFmt: "0"
                };
                if (isNaN(val)) {
                  nextCell.t = 's';
                } else {
                  // nextCell.t = 'n';
                  if (nextCell.v.includes('%')) {
                    nextCell.t = 'n';
                    nextCell.s.numFmt = '0.0%';
                    nextCell.v =
                      nextCell.v.replace(',', '').replace('%', '') / 100;
                  } else {
                    nextCell.t = 'n';
                    nextCell.s.numFmt = this.claculateNumFmt(nextCell.v.replace(',', ''));
                    nextCell.v = nextCell.v.replace(',', '');
                  }
                }
              }
            }
          }
          // var nextCell = ws[
          //   XLSX.utils.encode_cell(ws['!merges']![1].e)
          // ];
          // nextCell.s =
          // {
          //   font: {
          //     sz: 14,
          //     bold: true,
          //     color: { rgb: "FFFFAA00" }
          //   },
          //   border: {
          //     top: { style: 'thick', color: { rgb: "FFFFAA00" } },
          //     right: { style: 'medium', color: { rgb: "FFFFAA00" } },
          //     bottom: { style: 'medium', color: { rgb: "FFFFAA00" } }
          //   }
          // };

          // console.log(ws['!merges']![1]);

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
          XLSX.writeFile(wb, name + '.xlsx');
          this.spinner.hide();
        } catch (e) {}
        break;

        case 'excelpmo':
          try {
            // const ws = XLSX.utils.json_to_sheet(data);
            const ws = XLSX.utils.table_to_sheet(doc, { raw: true });
            console.log('ws ===================== ',ws)
            ws['!cols'] = [];
  
            // this.wrapAndCenterCell(ws.K2);
            var range = XLSX.utils.decode_range(ws['!ref']!);
            console.log('range ===================== ',range)
            for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
              ws['!cols'].push({ wpx: 150 })
            }
            var row;
            var rowNum;
            var colNum;
            for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
              for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
  
                
                var nextCell =
                  ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
  
                if (typeof nextCell !== 'undefined') {
                  let val = nextCell.v.replace(',', '').replace('%', '');
                  nextCell.s = {
                    font: {
                      sz: 12,
                      bold: rowNum == 0 ? true : false,
                    },
                    border: {
                      top: { style: 'thin', color: { rgb: '00000000' } },
                      bottom: { style: 'thin', color: { rgb: '00000000' } },
                      left: { style: 'thin', color: { rgb: '00000000' } },
                      right: { style: 'thin', color: { rgb: '00000000' } },
                    },
                    alignment: {
                      wrapText: true,
                      vertical: 'center',
                      horizontal: 'center',
                    },
                    // numFmt: "0"
                  };
                  if (isNaN(val)) {
                    nextCell.t = 's';
                  } else {
                    // nextCell.t = 'n';
                    if (nextCell.v.includes('%')) {
                      nextCell.t = 'n';
                      nextCell.s.numFmt = '0.0%';
                      nextCell.v =
                        nextCell.v.replace(',', '').replace('%', '') / 100;
                    } else {
                      nextCell.t = 'n';
                      nextCell.s.numFmt = this.claculateNumFmt(nextCell.v.replace(',', ''));
                      nextCell.v = nextCell.v.replace(',', '');
                    }
                  }
                }
              }
            }
            // var nextCell = ws[
            //   XLSX.utils.encode_cell(ws['!merges']![1].e)
            // ];
            // nextCell.s =
            // {
            //   font: {
            //     sz: 14,
            //     bold: true,
            //     color: { rgb: "FFFFAA00" }
            //   },
            //   border: {
            //     top: { style: 'thick', color: { rgb: "FFFFAA00" } },
            //     right: { style: 'medium', color: { rgb: "FFFFAA00" } },
            //     bottom: { style: 'medium', color: { rgb: "FFFFAA00" } }
            //   }
            // };
  
            // console.log(ws['!merges']![1]);
  
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, name + '.xlsx');
            this.spinner.hide();
          } catch (e) {}
          break;

        break;
        case 'excels':
          try {
            // const ws = XLSX.utils.json_to_sheet(data);
            const ws = XLSX.utils.table_to_sheet(doc, { raw: true });
            ws['!cols'] = [];
  
            // this.wrapAndCenterCell(ws.K2);
            var range = XLSX.utils.decode_range(ws['!ref']!);
            for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
              ws['!cols'].push({ wpx: 180 })
              
            }
            var row;
            var rowNum;
            var colNum;
            for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
              for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
  
                
                var nextCell =
                  ws[XLSX.utils.encode_cell({ r: rowNum, c: colNum })];
                  
                if (typeof nextCell !== 'undefined') {
                  let val = nextCell.v.replace(',', '').replace('%', '');
                  if(val == "TAG" || val == "Bifurcation" || val == "ToR" || val == "MoS" || val == "Revised Closure Date")
                  {
                    nextCell.s = {
                      font: {
                        sz: 14,
                        bold: rowNum == 0 ? true : false,
                        color: { rgb: 'ffffff' }
                      },
                      border: {
                        top: { style: 'thin', color: { rgb: '00000000' } },
                        bottom: { style: 'thin', color: { rgb: '00000000' } },
                        left: { style: 'thin', color: { rgb: '00000000' } },
                        right: { style: 'thin', color: { rgb: '00000000' } },
                      },
                      alignment: {
                        wrapText: true,
                        vertical: 'center',
                        horizontal: 'center',
                      },
                      fill : { // background color
          
                        fgColor: { rgb: "FFD700" },
                        bgColor: { rgb: "FFD700" } 
                    }
                      // numFmt: "0"
                    };
                  }
                  else if(val == "Sr.No." || val == "Project Name" || val == "Complexity" || val == "Sponsor" || val == "Business Lead" ||
                  val == "SME" || val == "PM" || val == "Status" || val == "RAG" || val == "New Project Addition Date" ||
                  val == "New Project Added (Year)" || val == "Actual Start Date" || val == "Start Year" || val == "Planned Closure Date" || val == "Actual Closure Date" ||
                  val == "Closure Year" || val == "Closure Quarter")
                  {
                    nextCell.s = {
                      font: {
                        sz: 14,
                        bold: rowNum == 0 ? true : false,
                        color: { rgb: '00000000' }
                      },
                      border: {
                        top: { style: 'thin', color: { rgb: '00000000' } },
                        bottom: { style: 'thin', color: { rgb: '00000000' } },
                        left: { style: 'thin', color: { rgb: '00000000' } },
                        right: { style: 'thin', color: { rgb: '00000000' } },
                      },
                      alignment: {
                        wrapText: true,
                        vertical: 'center',
                        horizontal: 'center',
                      },
                      fill : { // background color
          
                        fgColor: { rgb: "4682B4" },
                        bgColor: { rgb: "4682B4" } 
                    }
                      // numFmt: "0"
                    };
                  }
                  else {
                    nextCell.s = {
                      font: {
                        sz: 14,
                        bold: rowNum == 0 ? true : false,
                      },
                      border: {
                        top: { style: 'thin', color: { rgb: '00000000' } },
                        bottom: { style: 'thin', color: { rgb: '00000000' } },
                        left: { style: 'thin', color: { rgb: '00000000' } },
                        right: { style: 'thin', color: { rgb: '00000000' } },
                      },
                      alignment: {
                        wrapText: true,
                        vertical: 'center',
                        horizontal: 'center',
                      },
                      // numFmt: "0"
                    };
                  }
             
                  if (isNaN(val)) {
                    nextCell.t = 's';
                  } else {
                    // nextCell.t = 'n';
                    if (nextCell.v.includes('%')) {
                      nextCell.t = 'n';
                      nextCell.s.numFmt = '0.0%';
                      nextCell.v =
                        nextCell.v.replace(',', '').replace('%', '') / 100;
                    } else {
                      nextCell.t = 'n';
                      nextCell.s.numFmt = this.claculateNumFmt(nextCell.v.replace(',', ''));
                      nextCell.v = nextCell.v.replace(',', '');
                    }
                  }
                }
              }
            }
            // var nextCell = ws[
            //   XLSX.utils.encode_cell(ws['!merges']![1].e)
            // ];
            // nextCell.s =
            // {
            //   font: {
            //     sz: 14,
            //     bold: true,
            //     color: { rgb: "FFFFAA00" }
            //   },
            //   border: {
            //     top: { style: 'thick', color: { rgb: "FFFFAA00" } },
            //     right: { style: 'medium', color: { rgb: "FFFFAA00" } },
            //     bottom: { style: 'medium', color: { rgb: "FFFFAA00" } }
            //   }
            // };
  
            // console.log(ws['!merges']![1]);
  
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, name + '.xlsx');
            this.spinner.hide();
          } catch (e) {}
    }
  }

  transformToNearest(value: number): number {
    var zeros = -Math.floor(Math.log10(value) + 1);
    let num = 0;
    if (value < 1) {
      switch (zeros) {
        case 1:
          num = Number(value.toFixed(2));
          break;
        case 2:
          num = Number(value.toFixed(3));
          break;
        case 3:
          num = Number(value.toFixed(4));
          break;
        case 4:
          num = Number(value.toFixed(5));
          break;
        case 5:
          num = Number(value.toFixed(6));
          break;
        default:
          num = Number(value.toFixed(1));
      }
    }
    return value < 1 ? num : Number(value.toFixed(1));
  }

  claculateNumFmt(value: number): string {
    let length = 0;
    let numFmt = "0";
    if (Math.floor(value) == value) length = 0;
    else {
      length = value.toString().split(".")[1].length || 0;
      numFmt = "0." + Array.from({ length: length }, (v, i) => '0')
    }
    return numFmt;
  }
}
