import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { WebService } from "../http/web-service.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { ConfirmationComponent } from "src/app/shared/modal/confirmation/confirmation.component";
import { NonDismissableComponent } from "src/app/shared/modal/nondismissable/nondismissable.component";

@Injectable()
export class WebInterceptor implements HttpInterceptor {
    bsModalRef: any;
    constructor(private _router: Router, private web: WebService, private modalService: BsModalService) { }
    //function which will be called for all http calls
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        //how to update the request Parameters
        // let userData: Login = (JSON.parse(localStorage.getItem('userData')));
        // let updatedRequest;
        // if (userData) {
        //     updatedRequest = request.clone({
        //         setHeaders: {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             'Authorization': userData.apiKey,
        //             'Deviceid': localStorage.getItem('machineId'),
        //             'Devicetype': 'DESKTOP'
        //         }
        //     });
        // } else {
        //     updatedRequest = request.clone({
        //         setHeaders: {
        //             'Content-Type': 'application/x-www-form-urlencoded',
        //             'Deviceid': localStorage.getItem('machineId'),
        //             'Devicetype': 'DESKTOP'
        //         }
        //     });
        // }
        //logging the updated Parameters to browser's console;




        // request = request.clone({
        //     headers: request.headers.set('versions', version),
        // });
        // console.log(request, this.isEmptyObject(request.body));
        if (this.web.getUserInfoSorage() && !this.isEmptyObject(request.body)) {
            let version = this.web.getUserInfoSorage().version;
            // request.body.version = version;

            // modfiy name to "chidume"
           
            const body = { ...request.body, "version": version };

            request = request.clone({
                body
            })
        }
        // console.log("this.web.getUserInfoSorage()=", request.body);
        return next.handle(request).pipe(
            tap(
                event => {
                    //logging the http response to browser's console in case of a success
                    if (event instanceof HttpResponse) {
                        if (environment.debug) {

                        }

                    }
                },
                error => {
                    //logging the http response to browser's console in case of a failuer
                    if (error instanceof HttpErrorResponse) {
                        // if (environment.debug)
                        if (error.status === 401) {
                            this._router.navigate(['']);
                        }
                        if (error.status === 402) {
                            this.addFav(error.error.error);
                        }
                    }
                }
            )
        );
    }
    isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }
    addFav(message) {
        const initialState = {
            message: message
        };
        this.bsModalRef = this.modalService.show(NonDismissableComponent, {
            initialState,
            class: `modal-sm animate__slideInRight animate__animated animated_faster`,
            animated: true,
        });
        this.bsModalRef.content.notifyParent.subscribe(data => {

            if (data.result) {

                this._router.navigate(['']);
            }
        })
    }
}
